import "./main.css";

import { createElement } from "react";
import { createRoot } from "react-dom/client";

import { App, SuspenseRouter } from "./components";

const app = createElement(App, { router: SuspenseRouter });

const rootElement = document.createElement("div");
void document.body.append(rootElement);

document.documentElement.classList.add("dark");

const root = createRoot(rootElement);
void root.render(app);
