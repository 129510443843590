import localForage from "localforage";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface ColorPickerState {
  recentColors: string[];
  addRecentColor: (color: string) => void;
}

const MAX_RECENT_COLORS = 8;

export const useColorPickerStore = create<ColorPickerState>()(
  persist(
    (set) => ({
      recentColors: [],
      addRecentColor: (newColor: string) => {
        set((state) => {
          const newRecentColors = state.recentColors.filter(
            (color) => color !== newColor,
          );

          newRecentColors.unshift(newColor);

          if (newRecentColors.length > MAX_RECENT_COLORS) {
            newRecentColors.pop();
          }

          return { recentColors: newRecentColors };
        });
      },
    }),
    {
      version: 1,
      name: "colorPicker",
      getStorage: () => localForage,
    },
  ),
);
