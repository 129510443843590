import ErrorMessage from "./ErrorMessage";

export default function Error403() {
  return (
    <ErrorMessage
      title="Unauthorized"
      description="You do not have access to this workspace. Please use the sidebar to go back to a known page, or reach out to the workspace admins to request access."
    />
  );
}
