import _mixpanel, { OverridedMixpanel } from "mixpanel-browser";

interface MixpanelEnhanced extends OverridedMixpanel {
  __loaded?: boolean;
}

const mixpanel = _mixpanel as MixpanelEnhanced;

const safeTrack = (event, properties?) => {
  if (mixpanel.__loaded) {
    mixpanel.track(event, properties);
  }
};

export default {
  ...mixpanel,
  safeTrack,
};
