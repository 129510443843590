import { ComponentProps } from "react";
import { useEffect } from "react";
import { IntercomProvider as Provider, useIntercom } from "react-use-intercom";

import useBasicData from "../../hooks/useBasicData";
import layerConfig from "../../utils/config";

type LayerIntercomProviderProps = Omit<
  ComponentProps<typeof Provider>,
  "appId"
>;

export const IntercomProvider = (props: LayerIntercomProviderProps) => (
  <Provider {...props} appId={layerConfig.INTERCOM_APP_ID} autoBoot>
    <IntercomMetadataUpdater />
    {props.children}
  </Provider>
);

const IntercomMetadataUpdater = () => {
  const { update } = useIntercom();
  const { user, workspace } = useBasicData();

  useEffect(() => {
    if (user) {
      const customAttributes: Record<string, any> = {};
      if (user.personalWorkspace) {
        customAttributes.personal_workspace_tier =
          user.personalWorkspace.planTier;
      }

      if (workspace) {
        customAttributes.last_workspace_id = workspace.id;
        customAttributes.last_workspace_name = workspace.name;
        customAttributes.last_workspace_tier = workspace.planTier;
      }

      update({
        email: user.email,
        name: user.name,
        userId: user.id,
        userHash: user.intercomHash,
        avatar: {
          type: "avatar",
          imageUrl: user.profilePicture,
        },
        customAttributes,
      });
    }
  }, [user, workspace, update]);

  return null;
};
