import {
  LoadingButton as MuiButton,
  LoadingButtonProps as MuiButtonProps,
} from "@mui/lab";
import { styled } from "@mui/material";

export interface ButtonProps extends MuiButtonProps {
  rounded?: boolean;
}

const extraProps: PropertyKey[] = ["rounded"];

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<ButtonProps>(({ rounded = false, theme }) => ({
  ...(rounded && {
    borderRadius: "9999px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }),
})) as typeof Button;
