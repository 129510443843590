import { styled } from "@mui/system";

interface IndicatorContainerProps {
  severity?: "info" | "warning";
}

export const IndicatorContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "severity",
})<IndicatorContainerProps>(({ theme, severity = "info" }) => ({
  fontSize: 14,
  color: theme.palette.secondary[200],
  backgroundColor: theme.palette.secondary[950],
  borderBottomLeftRadius: 6,
  position: "absolute",
  top: 0,
  right: 0,
  lineHeight: 1,
  aspectRatio: 1,
  height: 24,
  width: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > svg": {
    color:
      severity === "warning"
        ? theme.palette.warning["600"]
        : theme.palette.secondary["200"],
  },
}));
