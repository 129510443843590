import ErrorMessage from "./ErrorMessage";

export default function Error404() {
  return (
    <ErrorMessage
      title="Not Found"
      description="This page does not exist. Please use the sidebar to go back to a known page."
    />
  );
}
