import * as turf from "@turf/turf";
import {
  BBox,
  Feature,
  MultiLineString,
  MultiPolygon,
  Polygon,
  Position as GeoJSONPosition,
} from "geojson";

import { Rectangle, StyleCapabilities } from "../../../../types";
import { Position, ResizeDirection } from "../module";

export function getRectanglesBBox(rects: Rectangle[]): BBox {
  return rects.filter(Boolean).reduce(
    (acc, rect) => {
      if (acc[0] > rect.x) acc[0] = rect.x;
      if (acc[1] > rect.y) acc[1] = rect.y;
      if (acc[2] < rect.x + rect.width) acc[2] = rect.x + rect.width;
      if (acc[3] < rect.y + rect.height) acc[3] = rect.y + rect.height;
      return acc;
    },
    [Infinity, Infinity, -Infinity, -Infinity] as BBox,
  );
}

// feature and geometry initializers
export function multiPolygonWithBBox(
  coordinates: GeoJSONPosition[][][],
): Feature<MultiPolygon> {
  const feature = turf.multiPolygon(coordinates);
  feature.bbox = turf.bbox(feature);
  return feature;
}

export function polygonWithBBox(
  coordinates: GeoJSONPosition[][],
): Feature<Polygon> {
  const feature = turf.polygon(coordinates);
  feature.bbox = turf.bbox(feature);
  return feature;
}

export function multiPolygonFromPoints(
  points: GeoJSONPosition[],
): Feature<MultiPolygon> {
  const coordinates = [[[...points, points[0]]]]; // close the ring
  return multiPolygonWithBBox(coordinates);
}

export function multiPolygonFromRectangle(
  rect: Rectangle,
): Feature<MultiPolygon> {
  const coordinates = [
    [
      [
        [rect.x, rect.y],
        [rect.x, rect.y + rect.height],
        [rect.x + rect.width, rect.y + rect.height],
        [rect.x + rect.width, rect.y],
        [rect.x, rect.y],
      ],
    ],
  ];
  return multiPolygonWithBBox(coordinates);
}

export function polygonFromBBox(bbox: BBox): Feature<Polygon> {
  const coordinates = [
    [
      [bbox[0], bbox[1]],
      [bbox[0], bbox[3]],
      [bbox[2], bbox[3]],
      [bbox[2], bbox[1]],
      [bbox[0], bbox[1]],
    ],
  ];
  return polygonWithBBox(coordinates);
}

// type conversion
export function getDimensionsFromBBox(bbox: BBox) {
  return {
    width: bbox[2] - bbox[0],
    height: bbox[3] - bbox[1],
  };
}

export function getBBoxFromRectangle(rect: Rectangle): BBox {
  return [rect.x, rect.y, rect.x + rect.width, rect.y + rect.height];
}

export function getRectangleFromBBox(bbox: BBox): Rectangle {
  return {
    x: bbox[0],
    y: bbox[1],
    ...getDimensionsFromBBox(bbox),
  };
}

// canvas geometry
export function getCanvasPosition(
  {
    scale,
    position,
    containerPosition,
  }: { scale: number; position: Position; containerPosition?: Position },
  x: number,
  y: number,
  frame?: BBox,
  containInFrame?: boolean,
) {
  let cx = (x - position.x - (containerPosition?.x || 0)) / scale;
  let cy = (y - position.y - (containerPosition?.y || 0)) / scale;
  if (frame) {
    cx -= frame[0];
    cy -= frame[1];
    if (containInFrame) {
      cx = Math.max(Math.min(cx, frame[2] - frame[0]), 0);
      cy = Math.max(Math.min(cy, frame[3] - frame[1]), 0);
    }
  }
  return { x: cx, y: cy };
}

export function getContainerPosition(
  { scale, position }: { scale: number; position: Position },
  x: number,
  y: number,
) {
  return {
    x: x * scale + position.x,
    y: y * scale + position.y,
  };
}

export function getContainerSize({ scale }: { scale: number }, bbox: BBox) {
  return {
    width: getDimensionsFromBBox(bbox).width * scale,
    height: getDimensionsFromBBox(bbox).height * scale,
  };
}

export function fitToContent(
  frame: BBox,
  viewportWidth: number,
  viewportHeight: number,
) {
  const width = frame[2] - frame[0];
  const height = frame[3] - frame[1];
  const scale = Math.min(viewportWidth / width, viewportHeight / height) / 1.5;
  const position = {
    x: (viewportWidth - width * scale) / 2 - frame[0] * scale,
    y: (viewportHeight - height * scale) / 2 - frame[1] * scale - 40,
  };
  return {
    scale,
    position,
  };
}

// transforms
export function padBBox(bbox: BBox, padding: number, limitBBox: BBox): BBox {
  return containBBox(
    [
      bbox[0] - padding,
      bbox[1] - padding,
      bbox[2] + padding,
      bbox[3] + padding,
    ],
    limitBBox,
  );
}

export function transformBBox(bbox: BBox, transform: Rectangle): BBox {
  return [
    bbox[0] + (transform?.x || 0),
    bbox[1] + (transform?.y || 0),
    bbox[2] + (transform?.x || 0) + (transform?.width || 0),
    bbox[3] + (transform?.y || 0) + (transform?.height || 0),
  ];
}

export function transformMultiPolygon(
  object: Feature<MultiPolygon>,
  transform: Rectangle,
): Feature<MultiPolygon> {
  const newCoordinates = object.geometry.coordinates.map((polygon) =>
    polygon.map((ring) =>
      ring.map((point) => [point[0] + transform.x, point[1] + transform.y]),
    ),
  );
  return multiPolygonWithBBox(newCoordinates);
}

export function inverseRectangle(rect: Rectangle): Rectangle {
  return {
    x: -rect.x,
    y: -rect.y,
    width: -rect.width,
    height: -rect.height,
  };
}

export function intersectBBox(bbox1: BBox, bbox2: BBox): BBox {
  return [
    Math.max(bbox1[0], bbox2[0]),
    Math.max(bbox1[1], bbox2[1]),
    Math.min(bbox1[2], bbox2[2]),
    Math.min(bbox1[3], bbox2[3]),
  ];
}

export function rectanglesBoundary(
  rectangles: Rectangle[],
): Feature<MultiPolygon | Polygon> {
  return rectangles
    .map((object) => multiPolygonFromRectangle(object))
    .reduce((acc, cur) => (acc ? turf.union(acc, cur) : cur), null);
}

export function containPoint(point: Position, bbox: BBox) {
  return {
    x: Math.max(bbox[0], Math.min(bbox[2], point.x)),
    y: Math.max(bbox[1], Math.min(bbox[3], point.y)),
  };
}

export function containRectangle(rect: Rectangle, container: BBox) {
  const newRect = {
    ...rect,
  };
  // fit width and height, preserving aspect ratio
  const maxWidth = container[2] - container[0];
  const maxHeight = container[3] - container[1];
  const width = roundTo((rect.width / rect.height) * maxHeight, 1, true);
  const height = roundTo((rect.height / rect.width) * maxWidth, 1, true);
  if (width > maxWidth) {
    newRect.width = maxWidth;
    newRect.height = height;
  } else {
    newRect.width = width;
    newRect.height = maxHeight;
  }

  // move x, y into the bbox as needed
  newRect.x = Math.max(
    container[0],
    Math.min(container[2] - newRect.width, newRect.x),
  );
  newRect.y = Math.max(
    container[1],
    Math.min(container[3] - newRect.height, newRect.y),
  );

  return newRect;
}

export function containBBox(bbox: BBox, container: BBox): BBox {
  // translate the frame to fit inside the limit bbox if needed
  const translateX = Math.min(
    Math.max(container[0] - bbox[0], 0),
    container[2] - bbox[2],
  );
  const translateY = Math.min(
    Math.max(container[1] - bbox[1], 0),
    container[3] - bbox[3],
  );
  if (translateX || translateY) {
    return [
      Math.max(bbox[0] + translateX, container[0]),
      Math.max(bbox[1] + translateY, container[1]),
      bbox[2] + translateX,
      bbox[3] + translateY,
    ];
  }
  return bbox;
}

export function unkinkMultiPolygon(multiPolygon: Feature<MultiPolygon>) {
  if (!multiPolygon) return null;
  let valid = true;
  multiPolygon.geometry.coordinates.forEach((polygon) => {
    polygon.forEach((ring) => {
      if (ring.length < 4) {
        valid = false;
      }
    });
  });
  if (!valid) {
    return null;
  }
  try {
    const unkinked = turf.unkinkPolygon(multiPolygon);
    return turf.multiPolygon(
      unkinked.features.map((f) => f.geometry.coordinates),
    );
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export function adjustTransform({
  transform,
  source,
  container,
  preserveSource,
  anchorPoints,
  roundDimensionsTo,
  activeEntityId,
  resizeDirection,
}: {
  transform: Rectangle;
  source: Rectangle;
  container?: Rectangle;
  preserveSource: boolean;
  anchorPoints: Record<string, GeoJSONPosition[]>;
  roundDimensionsTo: number;
  activeEntityId: string;
  resizeDirection?: ResizeDirection;
}) {
  const maxRoundTo = preserveSource ? 1 : roundDimensionsTo;
  let transformed = transformedRect({
    source,
    transform,
    isTransforming: true,
    anchor: { x: 0.5, y: 0.5 },
  }).rawRect;
  const containerBBox = container
    ? getBBoxFromRectangle(container)
    : [-Infinity, -Infinity, Infinity, Infinity];
  const sourceBBox = getBBoxFromRectangle(source);
  const aspectRatio = source.width / source.height;

  if (resizeDirection) {
    // check size limits
    const resizeBBox = container
      ? [
          maxRoundTo,
          maxRoundTo,
          roundTo(
            resizeDirection.includes("w")
              ? sourceBBox[2] - containerBBox[0]
              : containerBBox[2] - sourceBBox[0],
            maxRoundTo,
            true,
          ),
          roundTo(
            resizeDirection.includes("n")
              ? sourceBBox[3] - containerBBox[1]
              : containerBBox[3] - sourceBBox[1],
            maxRoundTo,
            true,
          ),
        ]
      : containerBBox;

    // preserve aspect ratio
    if (preserveSource && resizeDirection.length === 2) {
      if (transformed.width > transformed.height * aspectRatio) {
        transformed.height = transformed.width / aspectRatio;
      } else {
        transformed.width = transformed.height * aspectRatio;
      }
    }

    // check for anchor point matches
    if (anchorPoints) {
      const anchorTransform = findNearestTransformToAnchors({
        src: getBBoxFromRectangle(transformed),
        activeEntityId,
        anchorPoints,
        resizeDirection,
        aspectRatio: preserveSource ? aspectRatio : null,
      });
      if (anchorTransform) {
        if (preserveSource) {
          if (anchorTransform.width > anchorTransform.height * aspectRatio) {
            anchorTransform.height = anchorTransform.width / aspectRatio;
          } else {
            anchorTransform.width = anchorTransform.height * aspectRatio;
          }
        }
        transformed = transformedRect({
          source: transformed,
          transform: anchorTransform,
          isTransforming: true,
          anchor: { x: 0.5, y: 0.5 },
        }).rawRect;
      }
    }

    if (resizeDirection.includes("w") || resizeDirection.includes("e")) {
      transform.width =
        Math.max(
          resizeBBox[0],
          Math.min(resizeBBox[2], roundTo(transformed.width, maxRoundTo)),
        ) - source.width;
    }
    if (resizeDirection.includes("n") || resizeDirection.includes("s")) {
      transform.height =
        Math.max(
          resizeBBox[1],
          Math.min(resizeBBox[3], roundTo(transformed.height, maxRoundTo)),
        ) - source.height;
    }

    // move x and y if needed
    if (resizeDirection.includes("w")) {
      transform.x -= transform.width;
    }
    if (resizeDirection.includes("n")) {
      transform.y -= transform.height;
    }
  } else {
    // check position limits
    const positionBBox = container
      ? [
          0,
          0,
          roundTo(containerBBox[2] - containerBBox[0], maxRoundTo, true) -
            transformed.width,
          roundTo(containerBBox[3] - containerBBox[1], maxRoundTo, true) -
            transformed.height,
        ]
      : containerBBox;
    // preserve coordinates
    if (preserveSource) {
      if (Math.abs(transform.x) < Math.min(50, Math.abs(transform.y))) {
        transform.x = 0;
        transformed.x = source.x;
      }
      if (Math.abs(transform.y) < Math.min(50, Math.abs(transform.x))) {
        transform.y = 0;
        transformed.y = source.y;
      }
    }

    // check for anchor point matches
    if (anchorPoints) {
      const anchorTransform = findNearestTransformToAnchors({
        src: getBBoxFromRectangle(transformed),
        activeEntityId,
        anchorPoints,
      });
      if (anchorTransform) {
        transformed = transformedRect({
          source: transformed,
          transform: anchorTransform,
          isTransforming: true,
          anchor: { x: 0.5, y: 0.5 },
        }).rawRect;
      }
    }

    // contain
    if (container) {
      transform.x =
        Math.max(
          positionBBox[0],
          Math.min(
            positionBBox[2],
            roundTo(transformed.x - containerBBox[0], maxRoundTo),
          ),
        ) -
        source.x +
        containerBBox[0];
      transform.y =
        Math.max(
          positionBBox[1],
          Math.min(
            positionBBox[3],
            roundTo(transformed.y - containerBBox[1], maxRoundTo),
          ),
        ) -
        source.y +
        containerBBox[1];
    } else {
      transform.x = roundTo(transformed.x, maxRoundTo) - source.x;
      transform.y = roundTo(transformed.y, maxRoundTo) - source.y;
    }
  }
}

export function transformedRectFromBBox(
  bbox: BBox,
  isTransforming: boolean,
  transform: Rectangle,
) {
  if (!bbox) return null;
  return getRectangleFromBBox(
    isTransforming ? transformBBox(bbox, transform) : bbox,
  );
}

export function transformedRect({
  source,
  transform,
  isTransforming,
  anchor,
}: {
  source: Rectangle; // Original rectangle properties
  transform: Rectangle; // Deltas in change to the original rectangle properties
  isTransforming: boolean; // Is transformation active
  anchor: { x: number; y: number };
}): {
  rawRect: Rectangle;
  rect: Rectangle;
  anchoredRect: Rectangle;
  scale: { x: number; y: number };
} {
  if (!source) {
    return null;
  }

  const scale = {
    x: 1,
    y: 1,
  };

  const rawRect = {
    x: source.x + (isTransforming ? transform?.x || 0 : 0),
    y: source.y + (isTransforming ? transform?.y || 0 : 0),
    width: source.width + (isTransforming ? transform?.width || 0 : 0),
    height: source.height + (isTransforming ? transform?.height || 0 : 0),
  };

  // Temp working
  const newRect = { ...rawRect };

  if (newRect.width < 0) {
    newRect.width = -newRect.width; // Reverse back to positive
    scale.x = -1;
  }

  // Flipping image vertically
  if (newRect.height < 0) {
    newRect.height = -newRect.height; // Reverse back to positive
    scale.y = -1;
  }

  const anchoredRect = {
    x: newRect.x + scale.x * newRect.width * anchor.x,
    y: newRect.y + scale.y * newRect.height * anchor.y,
    width: newRect.width,
    height: newRect.height,
  };

  return {
    rawRect,
    rect: newRect,
    anchoredRect,
    scale,
  };
}

export function transformedRing(
  ring: turf.Position[],
  isTransforming: boolean,
  transform: Rectangle,
) {
  if (!isTransforming || !transform) {
    return ring;
  }
  return ring.map((point) => [point[0] + transform.x, point[1] + transform.y]);
}

export function transformScaleBBox(bbox: BBox, scale: number): BBox {
  const centroid = getCentroidOfBBox(bbox);
  const width = bbox[2] - bbox[0];
  const height = bbox[3] - bbox[1];
  const desiredWidth = width * scale;
  const desiredHeight = height * scale;
  return roundBBoxTo(
    [
      centroid[0] - desiredWidth / 2,
      centroid[1] - desiredHeight / 2,
      centroid[0] + desiredWidth / 2,
      centroid[1] + desiredHeight / 2,
    ],
    1,
  );
}

const invalidGenerationFrameWarning =
  "Generation not possible with this selection, try a different area and settings.";

// Increases density if the bbox is considerably smaller than the limit box to improve
// quality of forges
function autoAdjustDensity(pixelDensity: number, ratio: number): number {
  // Selected area 5% of the entire area
  if (ratio < 0.05) {
    return Math.max(pixelDensity, 3);
  }

  // Selected area 10% of entire area
  if (ratio < 0.1) {
    return Math.max(pixelDensity, 2);
  }

  // Selected area 25% of the entire area
  if (ratio < 0.25) {
    return Math.max(pixelDensity, 1.5);
  }

  return pixelDensity;
}

export function createGenerationFrame({
  bbox,
  limitBBox,
  capabilities,
  allTransparent,
  generationPaddingScale = 2,
  pixelDensity = 1,
}: {
  bbox: BBox;
  limitBBox: BBox;
  capabilities: StyleCapabilities;
  allTransparent: boolean;
  generationPaddingScale?: number;
  pixelDensity?: number;
}) {
  const centroid = getCentroidOfBBox(bbox);
  const limitWidth = limitBBox[2] - limitBBox[0];
  const limitHeight = limitBBox[3] - limitBBox[1];
  const width = bbox[2] - bbox[0];
  const height = bbox[3] - bbox[1];
  const selectionAspectRatio = width / height;

  let generationFrame: BBox = [...bbox];
  let generationWidth = width;
  let generationHeight = height;

  const bboxSize = height * width;
  const limitSize = limitHeight * limitWidth;
  const sizeRatio = bboxSize / limitSize;

  pixelDensity = autoAdjustDensity(pixelDensity, sizeRatio);

  if (allTransparent) {
    if (capabilities?.textToImageRatios) {
      // get a frame inside the bbox that matches textToImagRatios
      let bestMatch = 0;
      capabilities.textToImageRatios.forEach(({ width: w, height: h }) => {
        const sizeAspectRatio = w / h;
        let aspectRatioMatch = sizeAspectRatio / selectionAspectRatio;
        if (aspectRatioMatch > 1) {
          aspectRatioMatch = 1 / aspectRatioMatch;
        }
        if (aspectRatioMatch > bestMatch) {
          bestMatch = aspectRatioMatch;
          generationWidth = w;
          generationHeight = h;
          if (sizeAspectRatio < selectionAspectRatio) {
            // use full height, choose centered width
            const desiredWidth = roundTo(height * sizeAspectRatio, 1, true);
            generationFrame[0] =
              bbox[0] + roundTo((width - desiredWidth) / 2, 1);
            generationFrame[2] = generationFrame[0] + desiredWidth;
            generationFrame[1] = bbox[1];
            generationFrame[3] = bbox[3];
            pixelDensity = h / height;
          } else {
            // use full width, choose centered height
            const desiredHeight = roundTo(width / sizeAspectRatio, 1, true);
            generationFrame[0] = bbox[0];
            generationFrame[2] = bbox[2];
            generationFrame[1] =
              bbox[1] + roundTo((height - desiredHeight) / 2, 1);
            generationFrame[3] = generationFrame[1] + desiredHeight;
            pixelDensity = w / width;
          }
        }
      });
    }
  } else {
    const paddedWidth = Math.min(
      Math.max(
        width * generationPaddingScale,
        (capabilities.customSizeTarget || 0) / pixelDensity,
      ),
      limitWidth,
    );
    const paddedHeight = Math.min(
      Math.max(
        height * generationPaddingScale,
        (capabilities.customSizeTarget || 0) / pixelDensity,
      ),
      limitHeight,
    );

    // find the image to image frame that best covers the scaledLimitedBBox
    if (
      capabilities.customSizeStep &&
      capabilities.customSizeTarget &&
      capabilities.customSizeMin &&
      capabilities.customSizeMax
    ) {
      // find the right render density
      const minPixelDensity = Math.max(
        capabilities.customSizeMin / limitWidth,
        capabilities.customSizeMin / limitHeight,
      );
      const maxPixelDensity = Math.min(
        capabilities.customSizeMax / width,
        capabilities.customSizeMax / height,
      );
      if (minPixelDensity > maxPixelDensity) {
        // no valid shape possible
        return {
          warning: invalidGenerationFrameWarning,
        };
      } else if (minPixelDensity > pixelDensity) {
        pixelDensity = minPixelDensity;
      } else if (maxPixelDensity < pixelDensity) {
        pixelDensity = maxPixelDensity;
      }
      // get canvas dimensions for render density
      generationWidth = roundTo(
        Math.min(
          Math.max(paddedWidth * pixelDensity, capabilities.customSizeMin),
          capabilities.customSizeMax,
        ),
        capabilities.customSizeStep,
        true,
      );
      generationHeight = roundTo(
        Math.min(
          Math.max(paddedHeight * pixelDensity, capabilities.customSizeMin),
          capabilities.customSizeMax,
        ),
        capabilities.customSizeStep,
        true,
      );
      const canvasWidth = roundTo(generationWidth / pixelDensity, 1);
      const canvasHeight = roundTo(generationHeight / pixelDensity, 1);
      const x = roundTo(centroid[0] - canvasWidth / 2, 1);
      const y = roundTo(centroid[1] - canvasHeight / 2, 1);
      generationFrame = [x, y, x + canvasWidth, y + canvasHeight];
    } else if (capabilities.imageToImageRatios?.length) {
      let bestPixelDensity = 0;
      capabilities.imageToImageRatios.forEach(({ width: w, height: h }) => {
        // min
        const sizeMinPixelDensity = Math.max(w / limitWidth, h / limitHeight);
        // max
        const sizeMaxPixelDensity = Math.min(w / paddedWidth, h / paddedHeight);
        // find matches
        if (sizeMinPixelDensity > sizeMaxPixelDensity) {
          // no valid shape possible
          return;
        }
        const sizePixelDensity = Math.min(
          Math.max(sizeMinPixelDensity, pixelDensity),
          sizeMaxPixelDensity,
        );
        if (
          Math.abs(sizePixelDensity - pixelDensity) <
          Math.abs(bestPixelDensity - pixelDensity)
        ) {
          bestPixelDensity = sizePixelDensity;
        }
      });
      if (bestPixelDensity === 0) {
        return {
          warning: invalidGenerationFrameWarning,
        };
      }
      pixelDensity = bestPixelDensity;
      // get generation dimensions for render density
      const targetWidth = roundTo(paddedWidth * pixelDensity, 1, true);
      const targetHeight = roundTo(paddedHeight * pixelDensity, 1, true);
      const targetArea = targetWidth * targetHeight;
      // find the best area ratio match
      let bestAreaMatch = 0;
      capabilities.imageToImageRatios.forEach(({ width: w, height: h }) => {
        const sizeArea = w * h;
        const areaMatch = targetArea / sizeArea;
        if (w < targetWidth || h < targetHeight) {
          return;
        }
        if (areaMatch > bestAreaMatch) {
          bestAreaMatch = areaMatch;
          generationWidth = w;
          generationHeight = h;
        }
      });
      if (bestAreaMatch === 0) {
        // no valid shape possible
        return {
          warning: invalidGenerationFrameWarning,
        };
      }
      const canvasWidth = roundTo(generationWidth / pixelDensity, 1);
      const canvasHeight = roundTo(generationHeight / pixelDensity, 1);
      const x = roundTo(centroid[0] - canvasWidth / 2, 1);
      const y = roundTo(centroid[1] - canvasHeight / 2, 1);
      generationFrame = [x, y, x + canvasWidth, y + canvasHeight];
    }
    generationFrame = roundBBoxTo(containBBox(generationFrame, limitBBox), 1);
  }

  const returnFrame = roundBBoxTo(
    [
      (bbox[0] - generationFrame[0]) * pixelDensity,
      (bbox[1] - generationFrame[1]) * pixelDensity,
      (bbox[2] - generationFrame[0]) * pixelDensity,
      (bbox[3] - generationFrame[1]) * pixelDensity,
    ],
    1,
  );

  return {
    generationFrame,
    generationWidth,
    generationHeight,
    returnFrame,
    pixelDensity,
  };
}

// rounding
function roundToDecimal(value: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(value * factor) / factor;
}

export function roundTo(
  value: number,
  roundToNumber: number,
  floor?: boolean,
  ceil?: boolean,
) {
  const func = floor ? Math.floor : ceil ? Math.ceil : Math.round;
  const result = func(value / roundToNumber) * roundToNumber;
  return roundToDecimal(result, 4);
}

export function roundToLog(
  value: number,
  roundToBase: number,
  floor?: boolean,
  ceil?: boolean,
) {
  const func = floor ? Math.floor : ceil ? Math.ceil : Math.round;
  const result = Math.pow(
    roundToBase,
    func(Math.log(value) / Math.log(roundToBase)),
  );
  return roundToDecimal(result, 4);
}

export function roundPointTo(point: Position, roundToNumber: number): Position {
  return {
    x: roundTo(point.x, roundToNumber),
    y: roundTo(point.y, roundToNumber),
  };
}

export function roundBBoxTo(bbox: BBox, roundToNumber: number): BBox {
  return [
    roundTo(bbox[0], roundToNumber, true),
    roundTo(bbox[1], roundToNumber, true),
    roundTo(bbox[2], roundToNumber, false, true),
    roundTo(bbox[3], roundToNumber, false, true),
  ];
}

// measurements
export function getCentroidOfBBox(bbox: BBox) {
  if (!bbox) return [];
  return [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];
}

export function isFeatureRectangular(feature: Feature<MultiPolygon | Polygon>) {
  if (
    !feature ||
    !feature.geometry ||
    (feature.geometry.type !== "MultiPolygon" &&
      feature.geometry.type !== "Polygon")
  ) {
    return false;
  }
  const bbox = turf.bboxPolygon(turf.bbox(feature));
  const intersection = turf.intersect(bbox, feature);
  if (!intersection) {
    return false;
  }
  const overlap = turf.area(intersection) / turf.area(bbox);
  return overlap > 0.9999999;
}

export function isBBoxNonZero(bbox: BBox): boolean {
  return bbox && bbox[0] < bbox[2] - 1 && bbox[1] < bbox[3] - 1;
}

export function booleanMultiPolygonContains(
  feature1: Feature<MultiPolygon | Polygon>,
  feature2: Feature<MultiPolygon>,
) {
  const polygons1 =
    feature1.geometry.type === "MultiPolygon"
      ? feature1.geometry.coordinates
      : [feature1.geometry.coordinates];
  for (const polygon2 of feature2.geometry.coordinates) {
    for (const polygon1 of polygons1) {
      if (
        turf.booleanContains(turf.polygon(polygon1), turf.polygon(polygon2))
      ) {
        return true;
      }
    }
  }
  return false;
}

// matching anchor points
const maxAnchorDistance = 16;

export function getAnchors(bbox: BBox, round?: boolean): GeoJSONPosition[] {
  const [x1, y1, x2, y2] = round ? roundBBoxTo(bbox, 1) : bbox;
  const xMid = x1 + roundTo((x2 - x1) / 2, 1, true);
  const yMid = y1 + roundTo((y2 - y1) / 2, 1, true);
  const anchors = [
    [x1, y1],
    [x2, y1],
    [x1, y2],
    [x2, y2],
  ];
  const hasXmid = x2 - x1 > maxAnchorDistance * 10;
  const hasYmid = y2 - y1 > maxAnchorDistance * 10;
  if (hasXmid) {
    anchors.push([xMid, y1]);
    anchors.push([xMid, y2]);
  }
  if (hasYmid) {
    anchors.push([x1, yMid]);
    anchors.push([x2, yMid]);
  }
  if (hasXmid && hasYmid) {
    anchors.push([xMid, yMid]);
  }
  return anchors;
}

function findNearestTransformToAnchors({
  src,
  activeEntityId,
  anchorPoints,
  resizeDirection,
  aspectRatio,
}: {
  src: BBox;
  activeEntityId: string;
  anchorPoints: Record<string, GeoJSONPosition[]>;
  resizeDirection?: ResizeDirection;
  aspectRatio?: number;
}) {
  const srcPoints = getAnchors(src);
  const nearestTransform: Rectangle = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  let nearestDistance = Infinity;

  srcPoints.forEach((srcPoint) => {
    // find nearest target coord
    Object.entries(anchorPoints).forEach(([targetId, targetPoints]) => {
      if (targetId === activeEntityId) return;
      targetPoints.forEach((targetPoint) => {
        let distance = cartesianDistance(srcPoint, targetPoint);
        let xDistance = roundTo(targetPoint[0], 1, true) - srcPoint[0];
        let yDistance = roundTo(targetPoint[1], 1, true) - srcPoint[1];
        if (
          Math.abs(xDistance) > maxAnchorDistance &&
          Math.abs(yDistance) > maxAnchorDistance
        ) {
          return;
        }
        if (resizeDirection) {
          // require direction match and only change width/height
          if (
            (resizeDirection.includes("w") && xDistance > 0) ||
            (resizeDirection.includes("e") && xDistance < 0) ||
            (resizeDirection.includes("n") && yDistance > 0) ||
            (resizeDirection.includes("s") && yDistance < 0)
          ) {
            return;
          }
          if (aspectRatio) {
            const adjYTargetPoint = [
              targetPoint[0],
              srcPoint[0] + xDistance / aspectRatio,
            ];
            const adjYTargetDistance = cartesianDistance(
              srcPoint,
              adjYTargetPoint,
            );
            const adjXTargetPoint = [
              srcPoint[1] + yDistance * aspectRatio,
              targetPoint[1],
            ];
            const adjXTargetDistance = cartesianDistance(
              srcPoint,
              adjXTargetPoint,
            );
            if (adjYTargetDistance < adjXTargetDistance) {
              targetPoint = adjYTargetPoint;
              distance = adjYTargetDistance;
              yDistance = roundTo(targetPoint[1], 1, true) - srcPoint[1];
            } else {
              targetPoint = adjXTargetPoint;
              distance = adjXTargetDistance;
              xDistance = roundTo(targetPoint[0], 1, true) - srcPoint[0];
            }
          }
        }
        if (distance < nearestDistance) {
          nearestDistance = distance;
          if (resizeDirection) {
            nearestTransform.width =
              Math.abs(xDistance) <= maxAnchorDistance ? xDistance : 0;
            nearestTransform.height =
              Math.abs(yDistance) <= maxAnchorDistance ? yDistance : 0;
          } else {
            nearestTransform.x =
              Math.abs(xDistance) <= maxAnchorDistance ? xDistance : 0;
            nearestTransform.y =
              Math.abs(yDistance) <= maxAnchorDistance ? yDistance : 0;
          }
        }
      });
    });
  });

  return nearestTransform;
}

export function findMatchLines(
  src: BBox,
  activeEntityId: string,
  targetsAnchorPoints: Record<string, GeoJSONPosition[]>,
): Feature<MultiLineString> {
  const srcPoints = getAnchors(src);
  const lines: GeoJSONPosition[][] = [];

  for (let axis = 0; axis < 2; axis++) {
    const axisMatches: Record<number, number[]> = {};
    Object.entries(targetsAnchorPoints).forEach(([targetId, targetPoints]) => {
      if (targetId === activeEntityId) return;
      findMatchLinesAlongAxis(srcPoints, targetPoints, axis, axisMatches);
    });
    // filter out values that are not necessary
    Object.entries(axisMatches).forEach(
      ([srcCoordString, targetOrthoCoordinates]) => {
        const srcCoord = Number(srcCoordString);
        const srcOrthoCoordinates = srcPoints
          .filter((p) => p[axis] === srcCoord)
          .map((p) => p[(axis + 1) % 2]);
        const srcOrthoMinMax = [
          Math.min(...srcOrthoCoordinates),
          Math.max(...srcOrthoCoordinates),
        ];
        const line: GeoJSONPosition[] = [];
        srcOrthoMinMax
          .concat(...targetOrthoCoordinates)
          .sort((a, b) => (a < b ? -1 : 1))
          .forEach((coordinate) => {
            if (!line.some((p) => p[(axis + 1) % 2] === coordinate)) {
              if (axis === 0) {
                line.push([srcCoord, coordinate]);
              } else {
                line.push([coordinate, srcCoord]);
              }
            }
          });
        lines.push(line);
      },
    );
  }

  if (lines.length === 0) return null;
  return turf.multiLineString(lines);
}

function findMatchLinesAlongAxis(
  srcPoints: GeoJSONPosition[],
  targetPoints: GeoJSONPosition[],
  axis: number,
  axisMatches: Record<number, number[]>,
) {
  // get unique src coords along axis
  const srcCoords = srcPoints.reduce((acc, srcPoint) => {
    const srcCoord = srcPoint[axis];
    if (!acc.includes(srcCoord)) {
      acc.push(srcCoord);
    }
    return acc;
  }, []);
  // for each src coord, find target coords that match
  srcCoords.forEach((srcCoord) => {
    const matchedPoints = targetPoints
      .filter((p) => p[axis] === srcCoord)
      .map((p) => p[(axis + 1) % 2]);
    if (matchedPoints.length > 0) {
      if (!axisMatches[srcCoord]) {
        axisMatches[srcCoord] = [];
      }
      const minMatchedPoint = Math.min(...matchedPoints);
      const maxMatchedPoint = Math.max(...matchedPoints);
      axisMatches[srcCoord].push(minMatchedPoint);
      axisMatches[srcCoord].push(maxMatchedPoint);
    }
  });
}

function cartesianDistance(point1: GeoJSONPosition, point2: GeoJSONPosition) {
  return Math.sqrt(
    Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2),
  );
}
