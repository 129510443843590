import { ColorLens, ColorLensOutlined } from "@mui/icons-material";
import { SvgIconOwnProps } from "@mui/material";

type SvgIconFontSize = "inherit";

type StyleIconProps = {
  weight?: "solid" | "regular";
  className?: string;
};

export function StyleIcon({ weight = "solid", className }: StyleIconProps) {
  const iconProps: Partial<SvgIconOwnProps> = {
    fontSize: "inherit" as SvgIconFontSize,
    sx: {
      color: "currentColor",
      height: "1.25em",
      width: "1.25em",
      margin: "-0.125em",
    },
  };
  return weight === "solid" ? (
    <ColorLens {...iconProps} className={className} />
  ) : (
    <ColorLensOutlined {...iconProps} className={className} />
  );
}
