import { createSvgIcon } from "@mui/material";

export const RealtimeIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.75 6.65C20.75 4.77223 19.2278 3.25 17.35 3.25H12V20.75H17.35C19.2278 20.75 20.75 19.2278 20.75 17.35V6.65Z"
      fill="currentColor"
    />
    <path
      d="M2.6152 5.83565C2.87561 5.57524 3.26624 5.57524 3.50061 5.83565L4.04749 6.38253L2.84957 7.58045L2.3027 7.00753C2.06832 6.77315 2.06832 6.38253 2.3027 6.12211L2.6152 5.83565ZM8.08395 10.445C8.16207 10.5492 8.21416 10.6534 8.2402 10.7575L8.50061 12.0596L7.22457 11.7992C7.09436 11.7732 6.9902 11.695 6.91207 11.6169L3.73499 8.43982L4.93291 7.26795L8.08395 10.445ZM4.38603 4.95024C3.65686 4.22107 2.48499 4.19503 1.72978 4.95024L1.41728 5.26274C0.688115 5.9919 0.688115 7.16378 1.41728 7.89295L6.02666 12.5023C6.28707 12.7627 6.59957 12.945 6.96416 13.0232L9.20374 13.4659C9.38603 13.5179 9.62041 13.4659 9.75061 13.3096C9.90686 13.1534 9.98499 12.945 9.93291 12.7367L9.46416 10.4971C9.38603 10.1586 9.20374 9.82003 8.96936 9.58565L4.38603 4.95024Z"
      fill="currentColor"
    />
    <path
      d="M17.3125 3.25H16.375C16.0625 3.25 15.75 3.5625 15.75 3.875C15.75 4.22656 16.0625 4.5 16.375 4.5H17.3125C18.5234 4.5 19.5 5.51562 19.5 6.6875V7.625C19.5 7.97656 19.8125 8.25 20.125 8.25C20.4766 8.25 20.75 7.97656 20.75 7.625V6.6875C20.75 4.8125 19.2266 3.25 17.3125 3.25ZM20.125 6.5C19.8125 6.5 19.5 6.8125 19.5 7.125V16.875C19.5 17.2266 19.8125 17.5 20.125 17.5C20.4766 17.5 20.75 17.2266 20.75 16.875V7.125C20.75 6.8125 20.4766 6.5 20.125 6.5ZM3.875 13.5C3.5625 13.5 3.25 13.8125 3.25 14.125V16.875C3.25 17.2266 3.5625 17.5 3.875 17.5C4.22656 17.5 4.5 17.2266 4.5 16.875V14.125C4.5 13.8125 4.22656 13.5 3.875 13.5ZM3.25 16.375V17.3125C3.25 19.2266 4.8125 20.75 6.6875 20.75H7.625C7.97656 20.75 8.25 20.4766 8.25 20.125C8.25 19.8125 7.97656 19.5 7.625 19.5H6.6875C5.51563 19.5 4.5 18.5234 4.5 17.3125V16.375C4.5 16.0625 4.22656 15.75 3.875 15.75C3.5625 15.75 3.25 16.0625 3.25 16.375ZM19.5 16.375V17.3125C19.5 18.5234 18.5234 19.5 17.3125 19.5H16.375C16.0625 19.5 15.75 19.8125 15.75 20.125C15.75 20.4766 16.0625 20.75 16.375 20.75H17.3125C19.2266 20.75 20.75 19.2266 20.75 17.3125V16.375C20.75 16.0625 20.4766 15.75 20.125 15.75C19.8125 15.75 19.5 16.0625 19.5 16.375ZM16.875 20.75C17.2266 20.75 17.5 20.4766 17.5 20.125C17.5 19.8125 17.2266 19.5 16.875 19.5H7.125C6.8125 19.5 6.5 19.8125 6.5 20.125C6.5 20.4766 6.8125 20.75 7.125 20.75H16.875ZM17.5 3.875C17.5 3.5625 17.2266 3.25 16.875 3.25H7.125C6.8125 3.25 6.5 3.5625 6.5 3.875C6.5 4.22656 6.8125 4.5 7.125 4.5H16.875C17.2266 4.5 17.5 4.22656 17.5 3.875Z"
      fill="currentColor"
    />
  </svg>,
  "RealtimeIcon",
);
