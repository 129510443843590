import {
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
  styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface MenuListProps extends MuiMenuListProps {}

export const MenuList = styled(MuiMenuList)<MenuListProps>(() => {
  const theme = useTheme();
  const { spacing, palette } = theme;

  const styles = {
    minWidth: 210,
    backgroundColor: palette.secondary[900],
    border: `1px solid ${palette.secondary[700]}`,
    borderRadius: "8px",
    padding: spacing(1, 0),

    "& .MuiDivider-root": {
      borderColor: palette.secondary[700],
      margin: spacing(2, 0),
    },
  };

  return styles;
});
