const DEV = "dev.layer.ai";
const APP = "app.layer.ai";

export interface EnvConfig {
  mixpanelKey: string;
}

const EnvConfigs = {
  [DEV]: {
    mixpanelKey: "9a8ed8c3416c540d866da8fbea1f1571",
  },
  [APP]: {
    mixpanelKey: "f01f2415663795828ef3cc293a2024a9",
  },
};

export const getEnv = (): EnvConfig => {
  const hostname = window.location.hostname;
  return EnvConfigs[hostname] || EnvConfigs[DEV];
};
