import { useAuth0 } from "@auth0/auth0-react";
import { ComponentType } from "react";
import { Navigate, Outlet } from "react-router-dom";

import {
  useNextParam,
  useQueryWithResponseHandler,
  useReducerWithURLParams,
} from "../../hooks";
import { NullDataError } from "../errors/";
import { SyncUser } from "./queries.graphql";

type utmParams = {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
};

// uncomment below when graphql is ready
const withAccountRequired = <P extends object>(Component: ComponentType<P>) => {
  return function WithAccountRequired(props: P): JSX.Element {
    const { user } = useAuth0();
    const nextParam = useNextParam();
    const [utmParams] = useReducerWithURLParams<
      utmParams,
      Record<string, never>
    >(
      (payload) => payload,
      {},
      {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
      },
    );

    if (user == null) throw new NullDataError();
    const name = user.name || "";
    const firstName = user.given_name || "";
    const lastName = user.family_name || "";
    const profilePicture = user.picture || "";

    // NB: This is a query but may mutate backend state if the user doesn't exist.
    // We do this to make sure that the user is synced in our database and use a
    // query so that we can leverage suspense to block the rest of the app.
    const {
      data: { user: userResponse },
    } = useQueryWithResponseHandler(
      {
        query: SyncUser,
        variables: {
          input: {
            name,
            firstName,
            lastName,
            profilePicture,
            utmSource: utmParams.utm_source,
            utmMedium: utmParams.utm_medium,
            utmCampaign: utmParams.utm_campaign,
          },
        },
      },
      {
        redirectOnError: true,
      },
    );

    if (!userResponse) {
      return null;
    } else if (!userResponse.acceptedTos) {
      return <Navigate to={`/tos?next=${nextParam}`} />;
    } else {
      return <Component {...props} />;
    }
  };
};

const WithAccountRequired = withAccountRequired(Outlet);

export default WithAccountRequired;
