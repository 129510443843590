import { Alert as MuiAlert, AlertProps as MuiAlertProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins";
import React from "react";

export interface AlertProps extends MuiAlertProps {
  size?: "small" | "medium" | "large";
}

const extraProps: PropertyKey[] = ["size"];

const StyledAlert = styled(MuiAlert, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<AlertProps>(({ theme, size }) => {
  const { spacing, typography } = theme;

  const styles: CSSProperties = {};

  if (size === "small") {
    Object.assign(styles, {
      fontSize: typography.small.fontSize,
      lineHeight: typography.small.lineHeight,
      padding: spacing(1.5),
      "& .MuiAlert-icon": {
        fontSize: typography.regular.fontSize,
        padding: spacing(0.5, 0),
        marginRight: spacing(1),
      },
      "& .MuiAlert-message": {
        padding: spacing(0.25, 0),
      },
    });
  } else if (size === "large") {
    Object.assign(styles, {
      fontSize: typography.large.fontSize,
      lineHeight: typography.large.lineHeight,
      padding: spacing(3),
      "& .MuiAlert-icon": {
        fontSize: typography.title3.fontSize,
        padding: spacing(1, 0),
        marginRight: spacing(2),
      },
      "& .MuiAlert-message": {
        padding: spacing(1, 0),
      },
    });
  } else {
    Object.assign(styles, {
      fontSize: typography.regular.fontSize,
      lineHeight: typography.regular.lineHeight,
      padding: spacing(2),
      "& .MuiAlert-icon": {
        fontSize: typography.title4.fontSize,
        padding: spacing(0.5, 0),
        marginRight: spacing(1.5),
      },
      "& .MuiAlert-message": {
        padding: spacing(0.75, 0),
      },
    });
  }
  return styles;
});

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (props: AlertProps, ref) => {
    return <StyledAlert {...props} ref={ref} />;
  },
);
