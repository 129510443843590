import { Component, ErrorInfo, PropsWithChildren, ReactNode } from "react";

import config from "../../utils/config";
import Error500 from "./Error500";

type ErrorData = { error: Error; errorInfo: ErrorInfo };

type ErrorBoundaryProps = { topLevel?: boolean } & PropsWithChildren;
type ErrorBoundaryState = { errorData: ErrorData | undefined };

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  private static initialState: ErrorBoundaryState = { errorData: undefined };
  public state: ErrorBoundaryState = ErrorBoundary.initialState;

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ errorData: { error, errorInfo } });
  }

  public reset(): void {
    this.setState(ErrorBoundary.initialState);
  }

  public render(): ReactNode {
    if (this.state.errorData == null) {
      return this.props.children;
    }
    console.error(this.state.errorData.error);
    return (
      <Error500
        errorData={config.DEV_MODE ? this.state.errorData.error : null}
      />
    );
  }
}
