import * as yup from "yup";

import { MAX_STYLE_WEIGHT, MIN_STYLE_WEIGHT } from "../../constants";
import {
  FormTypeRealtime,
  MAX_CREATIVITY_REALTIME,
  MIN_CREATIVITY_REALTIME,
  REALTIME_FORM_FIELDS,
} from "./constants";

export const realtimeValidationSchema = yup.object().shape({
  [REALTIME_FORM_FIELDS.PROMPT_TEXT]: yup.string(),
  [REALTIME_FORM_FIELDS.STYLE]: yup.object().shape({
    id: yup.string().required(),
    weight: yup
      .number()
      .typeError("Invalid value.")
      .min(MIN_STYLE_WEIGHT, `Weight must be at least ${MIN_STYLE_WEIGHT}.`)
      .max(MAX_STYLE_WEIGHT, `Weight must be at most ${MAX_STYLE_WEIGHT}.`),
  }),
  [REALTIME_FORM_FIELDS.CREATIVITY]: yup
    .number()
    .required()
    .min(
      MIN_CREATIVITY_REALTIME,
      `Creativity must be at least ${MIN_STYLE_WEIGHT}.`,
    )
    .max(
      MAX_CREATIVITY_REALTIME,
      `Creativity must be at most ${MAX_CREATIVITY_REALTIME}.`,
    ),
}) as yup.ObjectSchema<FormTypeRealtime>;
