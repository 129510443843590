import { useMemo } from "react";
import { useIntercom } from "react-use-intercom";

import { useBasicData } from "../../../../../../../../../../hooks";
import { getConnectionNodesTyped } from "../../../../../../../../../../utils/relay";
import { WorkspaceDropdown } from "../WorkspaceDropdown/WorkspaceDropdown";

export const WorkspaceSelectionButton = () => {
  const { activeWorkspace, user } = useBasicData();

  const memberships = useMemo(
    () => getConnectionNodesTyped(user?.memberships),
    [user?.memberships],
  );

  const workspaces = useMemo(
    () => memberships.map((membership) => membership.workspace),
    [memberships],
  );

  const { showNewMessage } = useIntercom();

  if (!activeWorkspace || !activeWorkspace.myRole || !workspaces) {
    return null;
  }

  return (
    <WorkspaceDropdown
      allWorkspaces={workspaces}
      currentWorkspaceId={activeWorkspace.id}
      personalWorkspaceId={user?.personalWorkspace?.id}
      userEmail={user?.email}
      userFullName={user?.name}
      userProfilePictureUrl={user?.profilePicture}
      handleIntercomMessage={showNewMessage}
    />
  );
};
