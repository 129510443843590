import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, styled } from "@mui/system";

import { Button, Icon, Typography } from "../../../../../../../../../ui-v2";

const StyledButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  border: "none",
  borderRadius: 4,
  fontWeight: "400",

  "&:hover": {
    border: "none",
  },

  "& .MuiButton-startIcon": {
    transition: "margin 0.3s ease-out",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(1),
    fontSize: "16px",
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },
}));

export const HelpCenterButton = (props) => {
  return (
    <StyledButton
      {...props}
      variant="text"
      color="secondary"
      href="https://help.layer.ai/en/"
      target="_blank"
      fullWidth
      startIcon={
        <Box
          sx={{
            height: 28,
            width: 28,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            icon={icon({
              name: "circle-question",
              family: "sharp",
              style: "regular",
            })}
          />
        </Box>
      }
      endIcon={
        <>
          <Box
            sx={{
              fontSize: 14,
            }}
          >
            <Icon
              icon={icon({
                name: "arrow-up-right",
                family: "sharp",
                style: "regular",
              })}
            />
          </Box>
        </>
      }
    >
      <Typography variant="small" fontWeight={500} color="secondary.100">
        Help Center
      </Typography>
    </StyledButton>
  );
};
