import { createSvgIcon } from "@mui/material";

export const AspectRatioIcon = createSvgIcon(
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="27.5"
      height="27.5"
      rx="3.75"
      stroke="#373948"
      strokeWidth="0.5"
    />
    <rect x="6.5" y="7" width="15" height="15" rx="1.5" stroke="#858699" />
    <path d="M11.5 10H10C9.72386 10 9.5 10.2239 9.5 10.5V12" stroke="#858699" />
    <path
      d="M18.5 17V18.5C18.5 18.7761 18.2761 19 18 19H16.5"
      stroke="#858699"
    />
  </svg>,
  "AspectRatioIcon",
);
