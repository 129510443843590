import { createSvgIcon } from "@mui/material";

export const UpscaleIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6875 3.25H7.625C7.9375 3.25 8.25 3.5625 8.25 3.875C8.25 4.22656 7.9375 4.5 7.625 4.5H6.6875C5.47656 4.5 4.5 5.51562 4.5 6.6875V7.625C4.5 7.97656 4.1875 8.25 3.875 8.25C3.52344 8.25 3.25 7.97656 3.25 7.625V6.6875C3.25 4.8125 4.77344 3.25 6.6875 3.25ZM3.875 9.5C4.1875 9.5 4.5 9.8125 4.5 10.125V13.875C4.5 14.2266 4.1875 14.5 3.875 14.5C3.52344 14.5 3.25 14.2266 3.25 13.875V10.125C3.25 9.8125 3.52344 9.5 3.875 9.5ZM20.125 9.5C20.4375 9.5 20.75 9.8125 20.75 10.125V13.875C20.75 14.2266 20.4375 14.5 20.125 14.5C19.7734 14.5 19.5 14.2266 19.5 13.875V10.125C19.5 9.8125 19.7734 9.5 20.125 9.5ZM20.125 8.25C19.7734 8.25 19.5 7.97656 19.5 7.625V6.6875C19.5 5.51562 18.4844 4.5 17.3125 4.5H16.375C16.0234 4.5 15.75 4.22656 15.75 3.875C15.75 3.5625 16.0234 3.25 16.375 3.25H17.3125C19.1875 3.25 20.75 4.8125 20.75 6.6875V7.625C20.75 7.97656 20.4375 8.25 20.125 8.25ZM20.75 16.375V17.3125C20.75 19.2266 19.1875 20.75 17.3125 20.75H16.375C16.0234 20.75 15.75 20.4766 15.75 20.125C15.75 19.8125 16.0234 19.5 16.375 19.5H17.3125C18.4844 19.5 19.5 18.5234 19.5 17.3125V16.375C19.5 16.0625 19.7734 15.75 20.125 15.75C20.4375 15.75 20.75 16.0625 20.75 16.375ZM4.5 16.375V17.3125C4.5 18.5234 5.47656 19.5 6.6875 19.5H7.625C7.9375 19.5 8.25 19.8125 8.25 20.125C8.25 20.4766 7.9375 20.75 7.625 20.75H6.6875C4.77344 20.75 3.25 19.2266 3.25 17.3125V16.375C3.25 16.0625 3.52344 15.75 3.875 15.75C4.1875 15.75 4.5 16.0625 4.5 16.375ZM10.125 20.75C9.77344 20.75 9.5 20.4766 9.5 20.125C9.5 19.8125 9.77344 19.5 10.125 19.5H13.875C14.1875 19.5 14.5 19.8125 14.5 20.125C14.5 20.4766 14.1875 20.75 13.875 20.75H10.125ZM9.5 3.875C9.5 3.5625 9.77344 3.25 10.125 3.25H13.875C14.1875 3.25 14.5 3.5625 14.5 3.875C14.5 4.22656 14.1875 4.5 13.875 4.5H10.125C9.77344 4.5 9.5 4.22656 9.5 3.875Z"
      fill="currentColor"
    />
    <path
      d="M3.25 10.25C3.25 9.83579 3.58579 9.5 4 9.5H11.1C12.9778 9.5 14.5 11.0222 14.5 12.9V20C14.5 20.4142 14.1642 20.75 13.75 20.75H6.65C4.77223 20.75 3.25 19.2278 3.25 17.35V10.25Z"
      fill="currentColor"
    />
    <path
      d="M12.0214 12.6063V17.0008C12.0214 17.354 11.7271 17.6286 11.3936 17.6286C11.0405 17.6286 10.7658 17.354 10.7658 17.0008V14.1365L6.80287 18.0799C6.56745 18.3349 6.15546 18.3349 5.92004 18.0799C5.665 17.8444 5.665 17.4324 5.92004 17.197L9.86335 13.2341H6.99905C6.64592 13.2341 6.37126 12.9594 6.37126 12.6063C6.37126 12.2728 6.64592 11.9785 6.99905 11.9785H11.3936C11.7271 11.9785 12.0214 12.2728 12.0214 12.6063Z"
      fill="#15161C"
    />
  </svg>,
  "UpscaleIcon",
);
