import { useAuth0 } from "@auth0/auth0-react";
import mixpanel from "mixpanel-browser";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useBasicData } from "../../hooks";
import { getEnv } from "../../utils/env";
import { parameterizePathname } from "../../utils/url";
import routes from "../app/routes";

export const Analytics = (): JSX.Element => {
  const hasInitialized = useRef<boolean>(false);
  const { user } = useBasicData();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const identifyUser = () => {
    mixpanel.identify(user.id);

    mixpanel.people.set({
      $email: user.email,
      $name: user.name,
      avatar: user.profilePicture,
    });

    mixpanel.people.increment("sessions");
  };

  const initMixpanel = () => {
    // Only track users that are authenticated because then they have accepted EULA
    if (!hasInitialized.current && user && isAuthenticated) {
      const { mixpanelKey } = getEnv();

      mixpanel.init(mixpanelKey, {
        persistence: "localStorage",
        debug: __DEV__ as unknown as boolean,
      });

      identifyUser();

      hasInitialized.current = true;
    }
  };

  const trackRouteChanges = () => {
    if (hasInitialized.current) {
      mixpanel.track_pageview({
        path: parameterizePathname(routes, location),
      });
    }
  };

  useEffect(initMixpanel, [user, isAuthenticated]);
  useEffect(trackRouteChanges, [location]);

  return null;
};
