import { createSvgIcon } from "@mui/material";

export const Icon2D = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6875 3.25H7.625C7.9375 3.25 8.25 3.5625 8.25 3.875C8.25 4.22656 7.9375 4.5 7.625 4.5H6.6875C5.47656 4.5 4.5 5.51562 4.5 6.6875V7.625C4.5 7.97656 4.1875 8.25 3.875 8.25C3.52344 8.25 3.25 7.97656 3.25 7.625V6.6875C3.25 4.8125 4.77344 3.25 6.6875 3.25ZM3.875 6.5C4.1875 6.5 4.5 6.8125 4.5 7.125V16.875C4.5 17.2266 4.1875 17.5 3.875 17.5C3.52344 17.5 3.25 17.2266 3.25 16.875V7.125C3.25 6.8125 3.52344 6.5 3.875 6.5ZM20.125 6.5C20.4375 6.5 20.75 6.8125 20.75 7.125V16.875C20.75 17.2266 20.4375 17.5 20.125 17.5C19.7734 17.5 19.5 17.2266 19.5 16.875V7.125C19.5 6.8125 19.7734 6.5 20.125 6.5ZM20.125 8.25C19.7734 8.25 19.5 7.97656 19.5 7.625V6.6875C19.5 5.51562 18.4844 4.5 17.3125 4.5H16.375C16.0234 4.5 15.75 4.22656 15.75 3.875C15.75 3.5625 16.0234 3.25 16.375 3.25H17.3125C19.1875 3.25 20.75 4.8125 20.75 6.6875V7.625C20.75 7.97656 20.4375 8.25 20.125 8.25ZM20.75 16.375V17.3125C20.75 19.2266 19.1875 20.75 17.3125 20.75H16.375C16.0234 20.75 15.75 20.4766 15.75 20.125C15.75 19.8125 16.0234 19.5 16.375 19.5H17.3125C18.4844 19.5 19.5 18.5234 19.5 17.3125V16.375C19.5 16.0625 19.7734 15.75 20.125 15.75C20.4375 15.75 20.75 16.0625 20.75 16.375ZM4.5 16.375V17.3125C4.5 18.5234 5.47656 19.5 6.6875 19.5H7.625C7.9375 19.5 8.25 19.8125 8.25 20.125C8.25 20.4766 7.9375 20.75 7.625 20.75H6.6875C4.77344 20.75 3.25 19.2266 3.25 17.3125V16.375C3.25 16.0625 3.52344 15.75 3.875 15.75C4.1875 15.75 4.5 16.0625 4.5 16.375ZM7.125 20.75C6.77344 20.75 6.5 20.4766 6.5 20.125C6.5 19.8125 6.77344 19.5 7.125 19.5H16.875C17.1875 19.5 17.5 19.8125 17.5 20.125C17.5 20.4766 17.1875 20.75 16.875 20.75H7.125ZM6.5 3.875C6.5 3.5625 6.77344 3.25 7.125 3.25H16.875C17.1875 3.25 17.5 3.5625 17.5 3.875C17.5 4.22656 17.1875 4.5 16.875 4.5H7.125C6.77344 4.5 6.5 4.22656 6.5 3.875Z"
      fill="currentColor"
    />
    <path
      d="M8.31782 10.0542C8.88281 10.0542 9.38935 9.762 9.68159 9.27494C9.95434 8.80736 9.95434 8.2034 9.68159 7.71634C9.38935 7.24876 8.88281 6.93705 8.31782 6.93705C7.77231 6.93705 7.26577 7.24876 6.97353 7.71634C6.70078 8.2034 6.70078 8.80736 6.97353 9.27494C7.26577 9.762 7.77231 10.0542 8.31782 10.0542ZM17.1628 18.1469C17.7473 18.1469 18.2344 17.6599 18.2344 17.0754C18.2344 16.8611 18.1759 16.6663 18.0785 16.4909L13.8508 9.75C13.7339 9.55518 13.5001 9.4188 13.2469 9.4188C13.0131 9.4188 12.7793 9.55518 12.6624 9.75L10.2076 13.666L9.27246 12.1269C9.17505 12.01 9.03867 11.9125 8.88281 11.9125C8.74644 11.9125 8.59058 12.01 8.51265 12.1269L5.94097 16.3545C5.84355 16.5299 5.76562 16.7442 5.76562 16.9585C5.76562 17.6209 6.31113 18.1469 6.97353 18.1469H17.1628Z"
      fill="currentColor"
    />
  </svg>,
  "Icon2D",
);
