import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parse as uuidParse, v4 as uuidv4, version as uuidVersion } from "uuid";

import {
  AssetLayerObject,
  AssetLayerObjectType,
} from "../../../components/graphql/customTypes";
import { InferenceType } from "../../../components/inference/module";
import { RGBA } from "../../../types";
import { v1msec } from "../../../utils/uuid";
import { getRectangleFromBBox } from "./artboard/geometry";
import {
  CanvasAssetHistoryEntry,
  CanvasNewObjectCandidate,
  CanvasTool,
} from "./module";

const layer_950 = "#191A23";
const layer_900 = "#20212D";
const layer_800 = "#232434";
const layer_700 = "#2C2D3C";
const layer_600 = "#313248";
const layer_500 = "#41425B";
const layer_400 = "#52526F";
const layer_300 = "#858699";
const layer_200 = "#D2D3E0";
const layer_100 = "#E6E6EF";
// const layer_50 = "#FAFAFF";
const white = "#fff";

const violet_400 = "#C084FC";
const violet_500 = "#A855F7";
const violet_600 = "#9333EA";
const violet_700 = "#7E22CE";

const sky_500 = "#06B6D4";
const sky_600 = "#0891B2";

export const DARK_COLORS = {
  BACKGROUND_FILL: layer_950,
  BACKGROUND_DOTS: layer_600,
  FRAME_FILL: layer_800,
  FRAME_DOTS: layer_900,
  FRAME_GRID_COLOR: layer_600,
  FRAME_STROKE: layer_600,
  CROP_STROKE: layer_950,
  CROP_FILL: layer_400,
  ACTIVE_FILL: layer_900,
  ACTIVE_STROKE: violet_600,
  SEGMENTATION_STROKE: sky_500,
  GUIDELINE_STROKE: sky_600,
  HOVER_STROKE: violet_700,
  GENERATION_WINDOW_STROKE: violet_500,
  SELECTION_DASH_COLOR: layer_100,
  SELECTION_GAP_COLOR: layer_900,
  PRIMARY_FONT_COLOR: layer_300,
};

export const LIGHT_COLORS = {
  BACKGROUND_FILL: layer_200,
  BACKGROUND_DOTS: layer_200,
  FRAME_FILL: white,
  FRAME_DOTS: layer_100,
  FRAME_GRID_COLOR: layer_500,
  FRAME_STROKE: layer_400,
  CROP_STROKE: layer_200,
  CROP_FILL: layer_500,
  ACTIVE_FILL: layer_100,
  ACTIVE_STROKE: violet_500,
  SEGMENTATION_STROKE: sky_600,
  GUIDELINE_STROKE: sky_500,
  HOVER_STROKE: violet_400,
  GENERATION_WINDOW_STROKE: violet_600,
  SELECTION_DASH_COLOR: layer_900,
  SELECTION_GAP_COLOR: layer_100,
  PRIMARY_FONT_COLOR: layer_700,
};

export const useColors = () => {
  return DARK_COLORS;
};

export const CLASS_NAMES = {
  COMMON: "bg-white text-layer-600 dark:bg-layer-950 dark:text-layer-300",
  ACTIVE: "bg-layer-300 text-layer-800 dark:bg-layer-700 dark:text-layer-200",
  DISABLED: "text-layer-200 dark:text-layer-600",
  HOVER:
    "hover:bg-layer-300 hover:text-layer-800 dark:hover:bg-layer-700 dark:hover:text-layer-200",
  RING: "ring-1 ring-layer-300 dark:ring-layer-600",
  BORDER: "border-layer-300 dark:border-layer-700",
  BORDER_ACTIVE: "border-layer-300 dark:border-layer-600",
  BORDER_HOVER: "hover:border-layer-400 dark:hover:border-layer-600",
  SELECTED:
    "bg-violet-600 dark:bg-violet-500 text-layer-200 dark:text-layer-50",
  BUTTON:
    "group/button shrink-0 h-8 w-8 min-w-[1rem] p-0 flex items-center justify-center border-none rounded-md text-base text-[inherit]",
};

export const Z_INDEXES = {
  SEGMENTATION: 0,
  BACKGROUND: 10,
  BACKGROUND_COLOR: 200,
  CANVAS_FRAME: 100,
  OBJECT: 200,
  GENERATION_FRAME: 300,
  HOVER: 400,
  ACTIVE: 500,
  SELECTION: 550,
  TRANSFORMER: 600,
};

export function isTransformObjectEnabled(tool: CanvasTool) {
  return tool === "select";
}

export const fontFamily = `"inter", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`;

export function getRGBContrastColor({ r, g, b }: RGBA) {
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128
    ? { r: 0, g: 0, b: 0, a: 1 }
    : { r: 255, g: 255, b: 255, a: 1 };
}

export function getRGBAColorString(color: RGBA) {
  return `rgba(${color.r},${color.g},${color.b},${color.a})`;
}

export function getRGBColorNumber(color: RGBA) {
  return color.r * 256 * 256 + color.g * 256 + color.b;
}

export function getRGBAHexString({ r, g, b, a = 1 }: RGBA) {
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  const isTransparent = r === 0 && g === 0 && b === 0 && a === 0;

  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");
  const aHex = Math.round(a * 255)
    .toString(16)
    .padStart(2, "0");

  const hexColor = isTransparent
    ? `#${rHex}${gHex}${bHex}${aHex}`
    : `#${rHex}${gHex}${bHex}`;

  return hexColor.toLowerCase();
}

export const defaultAdjustmentFilter = {
  alpha: 1,
  gamma: 1,
  brightness: 1,
  contrast: 1,
  saturation: 1,
  red: 1,
  green: 1,
  blue: 1,
};

export function getInferenceType(
  entry: CanvasAssetHistoryEntry,
): InferenceType {
  const { isInpainting, hasTransparency, allTransparent } = entry || {};
  if (allTransparent) {
    return "text2image";
  }
  if (
    hasTransparency &&
    entry.contents.inferenceForm.parameters?.generationType === "REFILL"
  ) {
    return "outpainting";
  }
  if (isInpainting) {
    return "inpainting";
  }
  return "image2image";
}

export function getInferenceTypeInfo(activeVersion: CanvasAssetHistoryEntry) {
  const inferenceType = getInferenceType(activeVersion);
  switch (inferenceType) {
    case "text2image":
      return {
        type: "create",
        label: "Forging from Prompt",
        description: "Your forge will generate images from your prompt only.",
        icon: (
          <FontAwesomeIcon icon={regular("square-t")} className="text-sm" />
        ),
      };
    case "image2image":
    case "inpainting":
      return {
        type: "refine",
        label: "Forging using Guidance",
        description:
          "Your forge will generate images using the selection as guidance.",
        icon: (
          <FontAwesomeIcon
            icon={regular("image-polaroid")}
            className="text-sm"
          />
        ),
      };
    case "outpainting":
      return {
        type: "fill",
        label: "Filling Transparent Areas",
        description: "Your forge will fill transparent areas only.",
        icon: <FontAwesomeIcon icon={regular("fill")} className="text-sm" />,
      };
    default:
      return {
        type: "",
        label: "",
        description: "",
        icon: "",
      };
  }
}

export function makeNewLayerObject(object: Partial<AssetLayerObject>) {
  return {
    id: uuidv4(),
    versionId: uuidv4(),
    type: "IMAGE" as AssetLayerObjectType,
    x: 0,
    y: 0,
    width: 1,
    height: 1,
    rotation: 0,
    ...object,
  };
}

export function makeNewLayerObjectFromCandidate(
  candidate: CanvasNewObjectCandidate,
) {
  if (!candidate?.file?.inferenceParameters) return null;
  const selectionRect = getRectangleFromBBox(
    candidate.file.inferenceParameters.canvasGenerationBBox || [
      0,
      0,
      candidate.file.width,
      candidate.file.height,
    ],
  );
  return makeNewLayerObject({
    ...selectionRect,
    src: candidate.file.url,
    srcFrame: {
      x: 0,
      y: 0,
      width: candidate.file.width,
      height: candidate.file.height,
    },
    fileId: candidate.file.id,
    styleInferenceId: candidate.inference.id,
    inferenceParameters: {
      ...candidate.file.inferenceParameters,
      batchSize: candidate.inference.parameters.batchSize,
    },
  });
}

export function isVersionLatest(newVersionId: string, oldVersionId: string) {
  // if no versions, assume given version is latest
  if (!oldVersionId || !newVersionId) return true;

  const oldUUIDVersion = uuidVersion(oldVersionId);
  const newUUIDVersion = uuidVersion(newVersionId);

  // if versions are not uuid1, assume given version is latest
  if (oldUUIDVersion !== 1 || newUUIDVersion !== 1) {
    return true;
  }

  // parse as uuid v1
  const newVersionTime = v1msec(uuidParse(newVersionId));
  const oldVersionTime = v1msec(uuidParse(oldVersionId));

  return newVersionTime > oldVersionTime;
}
