import { ButtonBase } from "@mui/material";
import { styled } from "@mui/system";
import { ReactNode } from "react";

import { Typography } from "../../../../../ui-v2";

interface SessionNavigationItemProps {
  label: string;
  icon: ReactNode;
  active: boolean;
  onClick: () => void;
}

const Container = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  color: theme.palette.secondary["300"],
  gap: theme.spacing(1),
  width: "100%",
  position: "relative",
  padding: theme.spacing(1, 0),

  "& svg": {
    color: theme.palette.secondary["300"],
  },

  "&:focus": {
    outline: "none",

    "& .IconContainer": {
      backgroundColor: theme.palette.secondary["700"],
    },
  },

  "&:hover": {
    "& .IconContainer": {
      backgroundColor: theme.palette.secondary["700"],
    },
  },

  variants: [
    {
      props: ({ active }) => active,
      style: {
        color: theme.palette.secondary["100"],
        fontWeight: "bold",

        "& .IconContainer": {
          backgroundColor: theme.palette.secondary["700"],
        },

        "& svg": {
          color: theme.palette.secondary["100"],
        },

        "&:after": {
          content: "''",
          height: 28,
          borderRight: `2px solid ${theme.palette.primary["500"]}`,
          position: "absolute",
          top: 0,
          right: 0,
          marginTop: theme.spacing(1.5),
        },
      },
    },
  ],
}));

const IconContainer = styled("span")({
  display: "flex",
  width: 32,
  height: 32,
  borderRadius: 4,
  alignItems: "center",
  justifyContent: "center",
});

export const SessionNavigationItem = (props: SessionNavigationItemProps) => {
  const { icon, label, active, onClick } = props;
  return (
    <Container active={active} onClick={onClick} disableRipple>
      <IconContainer className="IconContainer">{icon}</IconContainer>
      <Typography variant="micro">{label}</Typography>
    </Container>
  );
};
