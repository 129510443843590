import { useContext } from "react";
import { UNSAFE_RouteContext } from "react-router-dom";

export default function usePathPattern() {
  let lastRouteContext = useContext(UNSAFE_RouteContext);
  while (lastRouteContext.outlet)
    lastRouteContext = lastRouteContext.outlet.props.routeContext;
  return lastRouteContext.matches
    .map(({ route: { path } }) => path)
    .filter(Boolean)
    .join("/")
    .replaceAll(/\/\*?\//g, "/");
}
