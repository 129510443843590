import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export default function WithAuthenticationRequired<P extends object>(props: P) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const { connection } = Object.fromEntries(searchParams);

  const returnTo = `${window.location.pathname}${window.location.search}`;

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    loginWithRedirect({
      authorizationParams: {
        prompt: "select_account",
        connection,
      },
      appState: { returnTo },
    });
  }, [isLoading, isAuthenticated, loginWithRedirect, connection, returnTo]);

  return isAuthenticated ? <Outlet {...props} /> : <></>;
}
