import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/material";
import { useLocation } from "react-router-dom";

import { useBasicData } from "../../../../../../../../hooks";
import { Icon } from "../../../../../../../ui-v2";
import { PrimaryNavigationLink } from "./components/PrimaryNavigationLink/PrimaryNavigationLink";

const Container = styled("nav")({
  display: "flex",
  flexDirection: "column",
});

export const PrimaryNavigationLinks = () => {
  const { pathname } = useLocation();
  const { activeWorkspace } = useBasicData();

  if (!activeWorkspace) {
    return null;
  }

  return (
    <Container>
      <PrimaryNavigationLink
        label="Home"
        to={`/${activeWorkspace.name}`}
        active={pathname === `/${activeWorkspace.name}`}
        icon={
          <Icon
            size="medium"
            icon={icon({
              name: "house-window",
              family: "sharp",
              style: "regular",
            })}
          />
        }
        activeIcon={
          <Icon
            size="medium"
            icon={icon({
              name: "house-window",
              family: "sharp",
              style: "solid",
            })}
          />
        }
      />
      {activeWorkspace.myPermissions.includes("asset:view") && (
        <PrimaryNavigationLink
          label="Drive"
          to={`/${activeWorkspace.name}/drive`}
          active={pathname.startsWith(`/${activeWorkspace.name}/drive`)}
          icon={
            <Icon
              size="medium"
              icon={icon({
                name: "layer-group",
                family: "sharp",
                style: "regular",
              })}
            />
          }
          activeIcon={
            <Icon
              size="medium"
              icon={icon({
                name: "layer-group",
                family: "sharp",
                style: "solid",
              })}
            />
          }
        />
      )}
      {activeWorkspace.myPermissions.includes("style:view") && (
        <PrimaryNavigationLink
          label="Styles"
          to={`/${activeWorkspace.name}/styles`}
          active={pathname.startsWith(`/${activeWorkspace.name}/styles`)}
          icon={
            <Icon
              size="medium"
              icon={icon({
                name: "palette",
                family: "sharp",
                style: "regular",
              })}
            />
          }
          activeIcon={
            <Icon
              size="medium"
              icon={icon({
                name: "palette",
                family: "sharp",
                style: "solid",
              })}
            />
          }
        />
      )}
      {activeWorkspace.myPermissions.includes("inference:view_history") && (
        <PrimaryNavigationLink
          label="History"
          to={`/${activeWorkspace.name}/prompts`}
          active={pathname.startsWith(`/${activeWorkspace.name}/prompts`)}
          icon={
            <Icon
              size="medium"
              icon={icon({
                name: "clock-rotate-left",
                family: "sharp",
                style: "regular",
              })}
            />
          }
          activeIcon={
            <Icon
              size="medium"
              icon={icon({
                name: "clock-rotate-left",
                family: "sharp",
                style: "solid",
              })}
            />
          }
        />
      )}
    </Container>
  );
};
