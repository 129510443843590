import {
  useBasicData,
  useQueryWithResponseHandler,
} from "../../../../../../../../hooks";
import { getConnectionNodesTyped } from "../../../../../../../../utils/relay";
import { Typography } from "../../../../../../../ui-v2";
import { SessionItem } from "./components/SessionItem/SessionItem";
import { GetRecentSessions } from "./queries.graphql";

export const RecentSessions = () => {
  const { activeWorkspace } = useBasicData();
  const canViewSessions =
    activeWorkspace?.name &&
    activeWorkspace?.myPermissions?.includes("session:view");
  const { data, error } = useQueryWithResponseHandler({
    query: GetRecentSessions,
    pause: !canViewSessions,
    variables: {
      workspaceName: activeWorkspace?.name,
      getSessionsInput: {
        status: ["ACTIVE"],
        first: 5,
        mineOnly: true,
      },
    },
  });

  const sessions = getConnectionNodesTyped(data?.workspace?.sessions);

  if (error || !activeWorkspace || sessions.length === 0) {
    return null;
  }

  return (
    <nav>
      <Typography
        fontWeight="700"
        variant="micro"
        color="secondary.300"
        sx={{ display: "inline-block", mb: 2, ml: 1 }}
      >
        Recent Sessions
      </Typography>
      {sessions.map((session) => (
        <SessionItem
          key={session.id}
          name={session.name}
          to={`/${activeWorkspace.name}/session/${session.id}`}
          iconUrl={session.previewUrls[0]}
        />
      ))}
    </nav>
  );
};
