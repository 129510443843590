import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ButtonBase, styled } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import tinycolor from "tinycolor2";

import { createRgbString } from "../../../utils/styling";
import { Button, Icon, IconButton, Popover, Typography } from "..";
import { useColorPickerStore } from "./state";

const PRESET_COLORS: string[] = [
  "#fecaca",
  "#fed7aa",
  "#fef08a",
  "#bbf7d0",
  "#bfdbfe",
  "#e9d5ff",
  "#fbcfe8",
  "#ffffff",
  "#dc2626",
  "#ea580c",
  "#eab308",
  "#16a34a",
  "#2563eb",
  "#9333ea",
  "#db2777",
  "#737373",
  "#991b1b",
  "#9a3412",
  "#854d0e",
  "#166534",
  "#1e40af",
  "#6b21a8",
  "#9d174d",
  "#000000",
];

const StyledColorPicker = styled(SketchPicker)(({ theme }) => ({
  boxShadow: "none !important",
  fontFamily: `${theme.typography.fontFamily} !important`,
  backgroundColor: `${theme.palette.secondary["900"]} !important`,
  padding: "0 !important",
  paddingTop: theme.spacing(1),

  "& input": {
    borderRadius: 4,
    color: theme.palette.secondary["100"],
    boxShadow: "none !important",
    border: `1px solid ${theme.palette.secondary["600"]} !important`,
    backgroundColor: theme.palette.secondary["800/75"],

    "&:focus,&:hover": {
      outline: "none",
      border: `1px solid ${theme.palette.primary["600"]} !important`,
    },
  },

  "& .flexbox-fix:nth-of-type(3) label": {
    color: `${theme.palette.secondary["100"]} !important`,
  },

  "& .flexbox-fix:nth-of-type(4)": {
    borderTop: "none !important",
    padding: `${theme.spacing(0, 0, 0, 2)} !important`,
  },
}));

const COLOR_SIZE = 20;

const Colors = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(auto-fill, minmax(${COLOR_SIZE}px, 1fr))`,
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

const Color = styled(ButtonBase)<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  borderRadius: 4,
  height: COLOR_SIZE,
  width: COLOR_SIZE,
}));

interface ColorPickerProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  disableAlpha?: boolean;
  initColor?: string;
  onColorChange?: (color: ColorResult) => void;
  onColorChangeCommitted?: (color: ColorResult) => void;
}

const getColorResult = (color: string): ColorResult => {
  const initialColor = tinycolor(color);
  return {
    hex: initialColor.toHex(),
    rgb: initialColor.toRgb(),
    hsl: initialColor.toHsl(),
  };
};

export const ColorPicker: FC<ColorPickerProps> = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    initColor = "#ffffff",
    disableAlpha,
    onColorChange = () => {},
    onColorChangeCommitted = () => {},
  } = props;

  const [color, setColor] = useState<ColorResult>(getColorResult(initColor));

  const { recentColors, addRecentColor } = useColorPickerStore();

  const isEyeDroppedSupported = !!window.EyeDropper;
  const hasRecentColors = recentColors.length > 0;

  useEffect(() => {
    onColorChange(color);
  }, [color]);

  const handleChangeColor = (color: ColorResult) => {
    setColor(color);
  };

  const handleCancel = () => {
    setColor(getColorResult(initColor));
    onClose();
  };

  const handleSelect = () => {
    onColorChangeCommitted(color);
    onClose();
  };

  const handleAddRecent = () => {
    addRecentColor(createRgbString(color.rgb));
  };

  const handleEyeDropper = async () => {
    if (isEyeDroppedSupported) {
      try {
        const eyeDropper = new window.EyeDropper();
        const result = await eyeDropper.open();
        setColor(getColorResult(result.sRGBHex));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleColorClick = (selectedColor: string) => () => {
    setColor(getColorResult(selectedColor));
  };

  return (
    <Popover
      open={open}
      onClose={handleSelect}
      anchorEl={anchorEl}
      TransitionProps={{
        onExited: handleAddRecent,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            p: 2,
          },
        },
      }}
    >
      {isEyeDroppedSupported && (
        <Stack alignItems="flex-end" sx={{ mb: 1 }}>
          <IconButton
            aria-label="Eyedropper"
            variant="quaternary"
            onClick={handleEyeDropper}
          >
            <Icon icon={solid("eye-dropper")} />
          </IconButton>
        </Stack>
      )}
      <StyledColorPicker
        color={color?.rgb || initColor}
        onChange={handleChangeColor}
        disableAlpha={disableAlpha}
        presetColors={[]}
      />
      <Stack sx={{ mt: 1 }}>
        <Typography variant="small" gutterBottom>
          Presets
        </Typography>
        <Colors>
          {PRESET_COLORS.map((color) => (
            <Color
              key={color}
              color={color}
              onClick={handleColorClick(color)}
            />
          ))}
        </Colors>
        {hasRecentColors && (
          <>
            <Typography variant="small" gutterBottom>
              Recent
            </Typography>
            <Colors>
              {recentColors.map((color) => (
                <Color
                  key={color}
                  color={color}
                  onClick={handleColorClick(color)}
                />
              ))}
            </Colors>
          </>
        )}
      </Stack>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button onClick={handleCancel} variant="text" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSelect} variant="outlined" color="primary">
          Select
        </Button>
      </Stack>
    </Popover>
  );
};
