import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Stack, styled } from "@mui/system";

import { Events } from "../../../../../constants/events";
import { useMixpanel } from "../../../../../hooks";
import { Icon, Link, Tooltip } from "../../..";

interface SDXLUpgradeIndicatorProps {
  upgradeUrl: string;
  userCanTrainStyles?: boolean;
}

const Container = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary["200"],
  backgroundColor: theme.palette.secondary["950"],
  borderBottomRightRadius: 6,
  padding: theme.spacing(1.5),
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1, 1.5),
  lineHeight: 1,
  maxWidth: "80%",

  "& > svg": {
    color: theme.palette.secondary["300"],
  },
}));

export const SDXLUpgradeIndicator = (props: SDXLUpgradeIndicatorProps) => {
  const { upgradeUrl, userCanTrainStyles } = props;
  const mixpanel = useMixpanel();

  let tooltipText =
    "This style can be upgraded to a better base model for better quality.";

  if (!userCanTrainStyles) {
    tooltipText += " Ask your workspace admin to upgrade the style.";
  }

  const handleUpgradeClick = () => {
    mixpanel.track(Events.BUTTON_CLICK, {
      type: "upgrade-to-sdxl",
    });
  };

  const tooltipTitleEl = (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <span>{tooltipText}</span>
      {userCanTrainStyles && (
        <Link
          color="primary"
          whiteSpace="nowrap"
          href={upgradeUrl}
          onClick={handleUpgradeClick}
        >
          Upgrade
        </Link>
      )}
    </Stack>
  );

  return (
    <Tooltip
      title={tooltipTitleEl}
      leaveDelay={250}
      slotProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              width: 240,
            },
          },
        },
      }}
    >
      <Container>
        <Icon size="medium" icon={duotone("up")} />
        <span>Upgrade</span>
      </Container>
    </Tooltip>
  );
};
