import { styled } from "@mui/system";
import { FC, PropsWithChildren } from "react";

const Container = styled("nav")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  position: "relative",
  overflow: "auto",
  padding: theme.spacing(3, 2, 0, 2),
  backgroundColor: theme.palette.secondary["1000"],
}));

export const PrimaryNavigationContainer: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return <Container>{children}</Container>;
};
