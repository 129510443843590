import * as yup from "yup";

import { FORM_OPTIONS_3D } from "./components/Options3D/Options3D";
import { FORM_FIELDS_3D, FormType3D } from "./constants";

export const meshValidationSchema = yup.object().shape({
  [FORM_FIELDS_3D.TARGET_FILE]: yup.object().when(FORM_FIELDS_3D.OPTION, {
    is: "IMAGE",
    then: (schema) => schema.required("Image reference file is required."),
    otherwise: (schema) => schema.notRequired(),
  }),
  [FORM_FIELDS_3D.PROMPT_TEXT]: yup.string().when(FORM_FIELDS_3D.OPTION, {
    is: "TEXT",
    then: (schema) => schema.required("Prompt text is required."),
    otherwise: (schema) => schema.notRequired(),
  }),
  [FORM_FIELDS_3D.NEGATIVE_PROMPT_TEXT]: yup.string(),
  [FORM_FIELDS_3D.TEXTURE]: yup.boolean(),
  [FORM_FIELDS_3D.OPTION]: yup.string().oneOf(FORM_OPTIONS_3D).required(),
  // Other fields not implemented yet
}) as yup.ObjectSchema<FormType3D>;
