import { AnyVariables, useQuery, UseQueryArgs } from "urql";

import {
  useHandleUrqlResponse,
  UseHandleUrqlResponseArgs,
} from "./useHandleUrqlResponse";

export function useQueryWithResponseHandler<
  Data extends { [key in string]?: any },
  Variables extends AnyVariables = AnyVariables,
>(
  queryArgs: UseQueryArgs<Variables, Data>,
  handlerArgs: Omit<UseHandleUrqlResponseArgs<Data, Variables>, "state"> = {},
) {
  const [state, execute] = useQuery(queryArgs);
  return {
    state,
    execute,
    ...useHandleUrqlResponse({
      state,
      ...handlerArgs,
    }),
  };
}
