import classNames from "classnames";

import { getAssetURL } from "../../utils/assets";

export default function MobileNotificationPage() {
  return (
    <main
      className={classNames(
        "h-full w-full px-6 py-16 mx-auto max-w-7xl h-full",
      )}
    >
      <img
        className="w-auto h-16 mx-auto sm:h-12"
        src={getAssetURL("branding/mark_color.png")}
        alt="Layer AI"
      />
      <div className="max-w-2xl mx-auto mt-20 text-center sm:mt-24">
        <p className="mt-4 text-base leading-7 text-layer-300 sm:mt-6 sm:text-lg sm:leading-8">
          Layer does not support mobile browsers yet. Please visit us on a
          desktop for the best experience.
        </p>
      </div>
    </main>
  );
}
