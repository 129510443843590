import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React from "react";

export type IconProps = Omit<FontAwesomeIconProps, "size"> & {
  size?: "xl" | "large" | "medium" | "small" | "micro" | "1x";
};

const SizeMap: Record<string, FontAwesomeIconProps["size"]> = {
  xl: "xl",
  large: "lg",
  medium: "sm",
  small: "xs",
  micro: "2xs",
  "1x": "1x",
};

export const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref) => {
    const { size = "medium", ...rest } = props;
    const faSize = SizeMap[size];
    return <FontAwesomeIcon ref={ref} size={faSize} {...rest} />;
  },
);
