import ErrorMessage from "./ErrorMessage";

type Props = { errorData?: Error };

export default function Error500({ errorData }: Props) {
  return (
    <ErrorMessage
      title="Internal Server Error"
      description="Sorry, it seems Layer is having issues serving this page. Our engineers have been notified of the issue and will be working to resolve it as soon as possible."
      showHelpLinks
      errorData={errorData}
    />
  );
}
