import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  AnyVariables,
  OperationResult,
  UseMutationState,
  UseQueryState,
} from "urql";

import { BasicErrorFragment, ErrorWithDataFragment } from "./queries.graphql";
import useNextParam from "./useNextParam";
import useShowSnackbar from "./useShowSnackbar";

type UrqlState<
  Data extends { [key in string]?: any },
  Variables extends AnyVariables = AnyVariables,
> =
  | UseQueryState<Data, Variables>
  | UseMutationState<Data, Variables>
  | OperationResult<Data, Variables>;

export interface GetResponseFromStateArgs<
  Data extends { [key in string]?: any },
  Variables extends AnyVariables = AnyVariables,
> {
  state: UrqlState<Data, Variables>;
  redirectOnError?: boolean;
  ignoredErrorCodes?: string[];
  successMessage?: string;
  errorMessage?: string;
  showSnackbarOnError?: boolean;
  getErrorMessage?: (err: BasicErrorFragment) => string | null;
  onError?: (err: ErrorWithDataFragment) => void;
  preventDuplicateErrorMessages?: boolean;
}

export type ResponseData<Data extends { [key in string]?: any }> = {
  [key in keyof Data]?: Exclude<Data[key], { __typename: "Error" }>;
};

type GetResponseFromStateResponse<Data extends { [key in string]?: any }> = {
  data: ResponseData<Data>;
  error?: ErrorWithDataFragment;
};

function defaultGetErrorMessage({ message }: BasicErrorFragment) {
  return message;
}

export function useUrqlResponseHandler() {
  const nextParam = useNextParam();
  const { showSuccess, showError } = useShowSnackbar();
  const navigate = useNavigate();
  const errorHandled = useRef<boolean>(false);

  const getResponseFromState = useCallback(
    <
      Data extends { [key in string]?: any },
      Variables extends AnyVariables = AnyVariables,
    >({
      state,
      successMessage,
      errorMessage,
      showSnackbarOnError = true,
      redirectOnError = false,
      ignoredErrorCodes = ["NOT_FOUND"],
      getErrorMessage = defaultGetErrorMessage,
      onError,
      preventDuplicateErrorMessages: preventDuplicateErrorMessages,
    }: GetResponseFromStateArgs<Data, Variables>) => {
      const response: GetResponseFromStateResponse<Data> = {
        data: {},
      };
      if (state.error) {
        response.error = {
          __typename: "Error",
          errorType: "INTERNAL_SERVER_ERROR",
          code: "NETWORK_ERROR",
          message: state.error.message,
          data: null,
        };
        if (!errorHandled.current && showSnackbarOnError) {
          showError(
            "Something went wrong connecting to the server. Please try again.",
          );
        }
        if (preventDuplicateErrorMessages) {
          errorHandled.current = true;
        }
        return response;
      }
      if (!state.data) {
        return response;
      }
      Object.keys(state.data).forEach((key: keyof Data) => {
        const dataValue = state.data[key];
        if (dataValue?.__typename === "Error") {
          response.error = {
            __typename: "Error",
            errorType: dataValue.errorType,
            code: dataValue.code,
            message: dataValue.message,
            data: dataValue.data,
          };
          if (response.error.code === "INVALID_JWT") {
            navigate(`/login?next=${nextParam}`);
          } else if (ignoredErrorCodes.includes(response.error.code)) {
            // ignore
          } else if (redirectOnError) {
            navigate(`/error?code=${dataValue.code}&next=${nextParam}`);
          } else {
            if (!errorHandled.current) {
              if (errorMessage) {
                showError(errorMessage);
              } else if (showSnackbarOnError) {
                const message = getErrorMessage(response.error);
                if (message) {
                  showError(message);
                }
              }
              if (onError) {
                onError(response.error);
              }
            }
            if (preventDuplicateErrorMessages) {
              errorHandled.current = true;
            }
          }
        } else {
          response.data[key] = dataValue;
          if (successMessage) {
            showSuccess(successMessage);
          }
        }
      });
      return response;
    },
    [showError, navigate, nextParam],
  );

  const resetErrorHandled = useCallback(() => {
    errorHandled.current = false;
  }, []);

  return { getResponseFromState, resetErrorHandled };
}
