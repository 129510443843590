import * as Sentry from "@sentry/react";
import { isBoolean } from "lodash";
import { mergeDeepRight, omit } from "ramda";

import { roundBBoxTo } from "../../../features/asset/canvas/artboard/geometry";
import { CanvasAssetHistoryEntry } from "../../../features/asset/canvas/module";
import { getInferenceType } from "../../../features/asset/canvas/utils";
import {
  AspectRatio,
  AspectRatioType,
  GenerationType,
  GuidanceFileParameters,
  InferenceForm,
  InferenceParameters,
} from "../../../types";
import { roundTo } from "../../../utils/numbers";
import { InferenceType } from "../../inference/module";
import { InferenceStyleFragment } from "../../inference/queries.graphql";
import {
  CanvasSelectionType,
  DEFAULT_2D_FORM_VALUES,
  DEFAULT_DENSITY,
  DimensionsType,
  FORM_FIELDS_2D,
  FormType2D,
  getDefaultReference,
  REFERENCES_METADATA,
  ReferenceType,
  ReferenceTypesType,
} from "../constants";
import { PresetType, ReferenceImageType } from "../constants";
import {
  FormTypeRealtime,
  REALTIME_FORM_FIELDS,
} from "../forms/Realtime/constants";

export const clip = (input, min, max) => {
  return Math.min(Math.max(input, min), max);
};

// Returns image dimensions adjusted to the style customSizeTarget.
export const getAdjustedReferenceImageDimensions = (
  style: InferenceStyleFragment,
  referenceImage: ReferenceImageType,
): DimensionsType => {
  const styleCustomSizeTargetWidth = style.capabilities.customSizeTarget;

  if (!styleCustomSizeTargetWidth) {
    return [referenceImage.width, referenceImage.height];
  }

  const newHeight =
    (styleCustomSizeTargetWidth * referenceImage.height) / referenceImage.width;

  const newHeightAdjusted = Math.floor(newHeight / 8) * 8; // Models like their values divisible by 8

  return [styleCustomSizeTargetWidth, newHeightAdjusted];
};

export const getWeightFromPercentage = (percentage: number) => {
  return Math.round((percentage / 100) * 100) / 100;
};

export const getPercentageFromWeight = (weight: number) => {
  return Math.round(weight * 100);
};

export const getWeightFromSimilarity = (similarity: number) => {
  return Math.round((similarity / 100) * 20) / 20;
};

export const getSimilarityFromWeight = (weight: number) => {
  return Math.round(weight * 100);
};

/**
 * Convert guidance scale to a prompt strength value between 0 and 100. High prompt strength means
 * the image generation will more closely follow the prompt. Low prompt strength means the image will
 * be more creative.
 *
 * - Guidance scale of 2 is prompt strength 100
 * - Guidance scale of 4 is prompt strength 50
 * - Guidance scale of 6 is prompt strength 0
 *
 * @param scale guidance scale
 *
 * @returns prompt strength, between 0 and 100
 */
export const guidanceScaleToPromptStrength = (scale: number) => {
  return Math.max(Math.min(roundTo(100 - (scale - 2) * 25, 1), 100), 0);
};

export const guidanceScaleFromPromptStrength = (strength: number) => {
  return Math.max(Math.min(roundTo((100 - strength) / 25 + 2, 0.05), 6), 2);
};

export const getPixelDensityFromSliderValue = (slider: number) => {
  return Math.round(Math.min(Math.max(slider, 1), 4) / 0.5) * 0.5;
};

export function getSliderValueFromPixelDensity(pixelDensity: number) {
  if (!pixelDensity) return 1;
  return Math.min(Math.max(pixelDensity, 1), 4);
}

export const getImageObject = (imageUrl: string): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = imageUrl;
  });

const omitParametersForAPI = (
  allParameters: InferenceParameters,
): InferenceParameters => {
  const parameters = omit(
    [
      "scheduler",
      "unenhancedPrompt",
      // fools typescript because these fields aren't registered on the frontend (but are on the backend)
      "promptRaw" as unknown as keyof InferenceParameters,
      "negativePromptRaw" as unknown as keyof InferenceParameters,
    ],
    allParameters,
  ) as InferenceParameters;

  return parameters;
};

const formatReferenceForAPI = (
  reference: ReferenceType,
): GuidanceFileParameters => ({
  type: reference.type,
  fileId: reference.image.driveFileId,
  url: reference.image.src,
  preprocess: REFERENCES_METADATA[reference.type].preprocess,
  weight: getWeightFromSimilarity(reference.similarity),
});

export const formatReferenceImageForAPI = (
  image: ReferenceImageType,
): GuidanceFileParameters => ({
  type: "INIT",
  url: image.src,
  preprocess: REFERENCES_METADATA.INIT.preprocess,
  weight: 1,
});

const formatCanvasFileForAPI = (
  file: GuidanceFileParameters,
  inferenceType: InferenceType,
): GuidanceFileParameters => ({
  url: file.url,
  type: file.type,
  preprocess: file.preprocess,
  fileId: file.fileId,
  weight: inferenceType === "outpainting" ? 1 : file.weight,
});
const getCanvasSelectionFromFiles = async (
  files: GuidanceFileParameters[],
  density: number,
): Promise<CanvasSelectionType> => {
  const canvasSelectionFile = files.find((file) => file.selection);

  if (canvasSelectionFile) {
    const imageObject = await getImageObject(canvasSelectionFile.url);

    return {
      type: canvasSelectionFile.type as ReferenceTypesType,
      image: {
        src: canvasSelectionFile.url,
        width: imageObject.width,
        height: imageObject.height,
      },
      similarity: getSimilarityFromWeight(canvasSelectionFile.weight),
      density,
    };
  }

  return DEFAULT_2D_FORM_VALUES[FORM_FIELDS_2D.CANVAS_SELECTION];
};

export const formatCanvasForForm2D = async (
  canvasInferenceForm: InferenceForm,
  defaultStyle: InferenceStyleFragment,
): Promise<FormType2D> => {
  const canvasInferenceFormParameters = canvasInferenceForm.parameters;
  let style = null;

  if (canvasInferenceFormParameters.styles[0]) {
    style = {
      id: canvasInferenceFormParameters.styles[0].id,
      weight: getPercentageFromWeight(
        canvasInferenceFormParameters.styles[0].weight,
      ),
    };
  } else if (defaultStyle) {
    style = { id: defaultStyle.id, weight: 100 };
  }

  return {
    [FORM_FIELDS_2D.STYLE]: style,
    [FORM_FIELDS_2D.PROMPT_LANGUAGE]: null,
    [FORM_FIELDS_2D.PROMPT_TEXT]: canvasInferenceFormParameters.prompt,
    [FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT]:
      canvasInferenceFormParameters.negativePrompt ||
      DEFAULT_2D_FORM_VALUES[FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT],
    [FORM_FIELDS_2D.PERFORMANCE]:
      canvasInferenceFormParameters.quality ||
      DEFAULT_2D_FORM_VALUES[FORM_FIELDS_2D.PERFORMANCE],
    [FORM_FIELDS_2D.SEED]:
      canvasInferenceFormParameters.seed === -1
        ? null
        : canvasInferenceFormParameters.seed,
    [FORM_FIELDS_2D.PROMPT_STRENGTH]: guidanceScaleToPromptStrength(
      canvasInferenceFormParameters.guidanceScale,
    ),
    [FORM_FIELDS_2D.DENOISING_STEPS]:
      canvasInferenceFormParameters.numInferenceSteps,
    [FORM_FIELDS_2D.TRANSPARENCY]:
      canvasInferenceFormParameters.transparency ||
      DEFAULT_2D_FORM_VALUES[FORM_FIELDS_2D.TRANSPARENCY],
    [FORM_FIELDS_2D.TILEABILITY]:
      canvasInferenceFormParameters.tileability ||
      DEFAULT_2D_FORM_VALUES[FORM_FIELDS_2D.TILEABILITY],
    [FORM_FIELDS_2D.DIMENSIONS]: [
      canvasInferenceFormParameters.width,
      canvasInferenceFormParameters.height,
    ],
    [FORM_FIELDS_2D.REFERENCES]: [],
    [FORM_FIELDS_2D.CANVAS_SELECTION]: await getCanvasSelectionFromFiles(
      canvasInferenceFormParameters.files,
      getSliderValueFromPixelDensity(canvasInferenceForm.pixelDensity),
    ),
  };
};

export const format2DFormForCanvas = (
  formValues: FormType2D,
  canvasInferenceForm: InferenceForm,
): InferenceForm => {
  let canvasInferenceFormParameters = canvasInferenceForm.parameters;

  if (!canvasInferenceFormParameters.maskImageUrl) {
    canvasInferenceFormParameters = omit(
      ["maskImageUrl"],
      canvasInferenceFormParameters,
    );
  }

  const density =
    formValues[FORM_FIELDS_2D.CANVAS_SELECTION]?.density || DEFAULT_DENSITY;

  return {
    defaults: { ...canvasInferenceForm.defaults },
    pixelDensity: getPixelDensityFromSliderValue(density),
    actualPixelDensity: getPixelDensityFromSliderValue(density),
    parameters: {
      ...canvasInferenceForm.parameters,
      files: formValues[FORM_FIELDS_2D.CANVAS_SELECTION]?.image?.src
        ? [
            {
              type: formValues[FORM_FIELDS_2D.CANVAS_SELECTION].type,
              selection: true,
              weight: getWeightFromSimilarity(
                formValues[FORM_FIELDS_2D.CANVAS_SELECTION].similarity,
              ),
              url: formValues[FORM_FIELDS_2D.CANVAS_SELECTION].image?.src,
            },
          ]
        : [],
      guidanceScale: guidanceScaleFromPromptStrength(
        formValues[FORM_FIELDS_2D.PROMPT_STRENGTH],
      ),
      width: formValues[FORM_FIELDS_2D.DIMENSIONS][0],
      height: formValues[FORM_FIELDS_2D.DIMENSIONS][1],
      negativePrompt: formValues[FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT],
      numInferenceSteps: formValues[FORM_FIELDS_2D.DENOISING_STEPS],
      prompt: formValues[FORM_FIELDS_2D.PROMPT_TEXT],
      promptLanguage: formValues[FORM_FIELDS_2D.PROMPT_LANGUAGE],
      quality: formValues[FORM_FIELDS_2D.PERFORMANCE],
      seed: formValues[FORM_FIELDS_2D.SEED] || -1,
      styles: [
        {
          id: formValues[FORM_FIELDS_2D.STYLE].id,
          weight: getWeightFromPercentage(
            formValues[FORM_FIELDS_2D.STYLE].weight,
          ),
        },
      ],
      tileability: formValues[FORM_FIELDS_2D.TILEABILITY],
      transparency: formValues[FORM_FIELDS_2D.TRANSPARENCY],
    },
  };
};

export const formatCanvasInferenceParametersForAPI = (
  assetVersion: CanvasAssetHistoryEntry,
): InferenceParameters => {
  const { contents, generationBBox } = assetVersion;
  const inferenceType = getInferenceType(assetVersion);

  const extraParameters: DeepPartial<InferenceParameters> = {
    returnMaskedAreaOnly: true,
    canvasGenerationBBox: roundBBoxTo(generationBBox, 1),
    canvasFrameBBox: roundBBoxTo(contents.frame, 1),
  };

  const parameters = mergeDeepRight(
    { ...contents.inferenceForm.parameters },
    extraParameters || {},
  ) as InferenceParameters;

  parameters.files = parameters.files?.map((file) =>
    formatCanvasFileForAPI(file, inferenceType),
  );

  if (parameters.generationType === "UPSCALE") {
    parameters.batchSize = 1;
  }

  return omitParametersForAPI(parameters);
};

export const formatForm2DValuesForAPI = (
  inputForm: FormType2D,
  defaultParameters: InferenceParameters,
): InferenceParameters => {
  let formattedReferences = [];

  try {
    formattedReferences = inputForm[FORM_FIELDS_2D.REFERENCES].map(
      formatReferenceForAPI,
    );
  } catch (e) {
    // Adding this here to debug this error:
    // https://layerai.sentry.io/issues/5947709641/
    Sentry.captureException(e, {
      extra: { references: inputForm[FORM_FIELDS_2D.REFERENCES] },
    });
  }

  const parameters = {
    ...defaultParameters,
    generationType: "CREATE" as GenerationType,
    width: inputForm[FORM_FIELDS_2D.DIMENSIONS][0],
    height: inputForm[FORM_FIELDS_2D.DIMENSIONS][1],
    prompt: inputForm[FORM_FIELDS_2D.PROMPT_TEXT],
    negativePrompt: inputForm[FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT],
    promptLanguage: inputForm[FORM_FIELDS_2D.PROMPT_LANGUAGE],
    transparency: inputForm[FORM_FIELDS_2D.TRANSPARENCY],
    tileability: inputForm[FORM_FIELDS_2D.TILEABILITY],
    seed: inputForm[FORM_FIELDS_2D.SEED] || -1,
    files: formattedReferences,
    numInferenceSteps: inputForm[FORM_FIELDS_2D.DENOISING_STEPS],
    guidanceScale: guidanceScaleFromPromptStrength(
      inputForm[FORM_FIELDS_2D.PROMPT_STRENGTH],
    ),
    styles: [],
    quality: inputForm[FORM_FIELDS_2D.PERFORMANCE],
  };
  if (inputForm[FORM_FIELDS_2D.STYLE]) {
    parameters.styles.push({
      id: inputForm[FORM_FIELDS_2D.STYLE].id,
      weight: getWeightFromPercentage(inputForm[FORM_FIELDS_2D.STYLE].weight),
    });
  }

  return parameters;
};

export const formatRealtimeFormValuesForAPI = (
  inputForm: FormTypeRealtime,
  defaultParameters: InferenceParameters,
): InferenceParameters => {
  const parameters: InferenceParameters = {
    ...defaultParameters,
    generationType: "REALTIME",
    batchSize: 1,
    styles: [],
    prompt: inputForm[REALTIME_FORM_FIELDS.PROMPT_TEXT],
    promptLanguage: inputForm[REALTIME_FORM_FIELDS.PROMPT_LANGUAGE],
    creativity: getWeightFromPercentage(
      inputForm[REALTIME_FORM_FIELDS.CREATIVITY],
    ),
    width: inputForm[REALTIME_FORM_FIELDS.DIMENSIONS][0],
    height: inputForm[REALTIME_FORM_FIELDS.DIMENSIONS][1],
  };
  if (inputForm[REALTIME_FORM_FIELDS.STYLE]) {
    parameters.styles.push({
      id: inputForm[REALTIME_FORM_FIELDS.STYLE].id,
      weight: getWeightFromPercentage(
        inputForm[REALTIME_FORM_FIELDS.STYLE].weight,
      ),
    });
  }

  return parameters;
};

export const formatInferenceParamsForFormType2D = async (
  params: InferenceParameters,
): Promise<Partial<FormType2D>> => {
  const outputForm: Partial<FormType2D> = {};

  if (params) {
    if (params.width && params.height) {
      outputForm[FORM_FIELDS_2D.DIMENSIONS] = [params.width, params.height];
    }
    if (params.styles?.length) {
      outputForm[FORM_FIELDS_2D.STYLE] = {
        id: params.styles[0].id,
        weight: getPercentageFromWeight(params.styles[0].weight),
      };
    }
    if (typeof params.prompt !== "undefined") {
      outputForm[FORM_FIELDS_2D.PROMPT_TEXT] = params.prompt;
    }
    if (typeof params.negativePrompt !== "undefined") {
      outputForm[FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT] = params.negativePrompt;
    }
    if (typeof params.promptLanguage !== "undefined") {
      outputForm[FORM_FIELDS_2D.PROMPT_LANGUAGE] = params.promptLanguage;
    }

    if (isBoolean(params.transparency)) {
      outputForm[FORM_FIELDS_2D.TRANSPARENCY] = params.transparency;
    }
    if (isBoolean(params.tileability)) {
      outputForm[FORM_FIELDS_2D.TILEABILITY] = params.tileability;
    }

    if (params.quality) {
      outputForm[FORM_FIELDS_2D.PERFORMANCE] = params.quality;
    }

    if (typeof params.tileability !== "undefined") {
      outputForm[FORM_FIELDS_2D.TILEABILITY] = params.tileability;
    }

    if (typeof params.numInferenceSteps !== "undefined") {
      outputForm[FORM_FIELDS_2D.DENOISING_STEPS] = params.numInferenceSteps;
    }

    if (typeof params.guidanceScale !== "undefined") {
      outputForm[FORM_FIELDS_2D.PROMPT_STRENGTH] =
        guidanceScaleToPromptStrength(params.guidanceScale);
    }

    if (params.files?.length) {
      outputForm[FORM_FIELDS_2D.REFERENCES] = await Promise.all(
        params.files.map(async (file) => {
          const type = file.type as ReferenceTypesType;
          const similarity = getSimilarityFromWeight(file.weight);
          let image;

          const imageObject = await getImageObject(file.url);
          if (imageObject) {
            image = {
              src: file.url,
              width: imageObject.width,
              height: imageObject.height,
            };
          }

          return getDefaultReference({ type, image, similarity });
        }),
      );
    }
  }

  return outputForm;
};

const getPresetAspectRatioFields = (
  h: number,
  w: number,
): Pick<PresetType, "aspectRatio" | "aspectRatioLabel"> => ({
  aspectRatioLabel: `${h}:${w}`,
  aspectRatio: h / w,
});

const ASPECT_RATIO_MAP: Record<
  AspectRatioType,
  Omit<PresetType, "dimensions">
> = {
  SQUARE: { label: "Square", ...getPresetAspectRatioFields(1, 1) },
  SQUARE_HD: { label: "Square HD", ...getPresetAspectRatioFields(1, 1) },
  LANDSCAPE_3_2: { label: "Landscape", ...getPresetAspectRatioFields(3, 2) },
  LANDSCAPE_4_3: { label: "Landscape", ...getPresetAspectRatioFields(4, 3) },
  LANDSCAPE_16_9: { label: "Landscape", ...getPresetAspectRatioFields(16, 9) },
  PORTRAIT_2_3: { label: "Portrait", ...getPresetAspectRatioFields(2, 3) },
  PORTRAIT_3_4: { label: "Portrait", ...getPresetAspectRatioFields(3, 4) },
  PORTRAIT_9_16: { label: "Portrait", ...getPresetAspectRatioFields(9, 16) },
};

const SORTED_ASPECT_RATIO_TYPES = Object.keys(
  ASPECT_RATIO_MAP,
) as AspectRatioType[];

export const getPresetFromAspectRatio = (
  aspectRatio: AspectRatio,
): PresetType => ({
  ...ASPECT_RATIO_MAP[aspectRatio.type],
  dimensions: [aspectRatio.width, aspectRatio.height],
});

export const getDimensionsString = (dimensions: DimensionsType) =>
  `${dimensions[0]}x${dimensions[1]}`;

// Needed to center the preview rectangle correctly in the container
export const getPreviewRectangleContainerDirection = (
  dimensions: DimensionsType,
): string => (dimensions[0] > dimensions[1] ? "row" : "column");

export const getAreDimensionsEqual = (
  dimensions1: DimensionsType,
  dimensions2: DimensionsType,
) => dimensions1[0] === dimensions2[0] && dimensions1[1] === dimensions2[1];

export const getAreAspectRatiosEqual = (
  dimensions1: DimensionsType,
  dimensions2: DimensionsType,
) => dimensions1[0] / dimensions1[1] === dimensions2[0] / dimensions2[1];

export const getPresetsFromAspectRatios = (
  aspectRatios: AspectRatio[],
): PresetType[] =>
  [...aspectRatios]
    .sort(
      (a, b) =>
        SORTED_ASPECT_RATIO_TYPES.indexOf(a.type) -
        SORTED_ASPECT_RATIO_TYPES.indexOf(b.type),
    )
    .map(getPresetFromAspectRatio);

export const getReferencesWithUnsupportedAspectRatio = (
  references: ReferenceType[],
  stylePresets: PresetType[],
) =>
  references.filter((reference) => {
    return !stylePresets.some(
      (stylePreset) =>
        stylePreset.dimensions[0] === reference.image.width &&
        stylePreset.dimensions[1] === reference.image.height,
    );
  });

// We only add the aspect ratio presets from references if the aspect ratio is not already included in style aspect ratios.
export const getAspectRatioPresetsFromReferences = (
  references: ReferenceType[],
  style: InferenceStyleFragment,
): PresetType[] =>
  references.map((reference) => {
    const adjustedImageDimensions = getAdjustedReferenceImageDimensions(
      style,
      reference.image,
    );

    return {
      label: REFERENCES_METADATA[reference.type].label,
      aspectRatioLabel: "",
      aspectRatio: reference.image.width / reference.image.height,
      dimensions: adjustedImageDimensions,
    };
  });
