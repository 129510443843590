import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  Theme,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

import { Button, ButtonProps } from "../../ui-v2";

interface ConfirmDialogProps {
  title: string;
  description: string;
  open: boolean;
  onConfirm: (event: React.MouseEvent) => void;
  onClose: (event: React.MouseEvent) => void;
  confirmButtonLabel?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
}

const Dialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "16px",
    backgroundColor: theme.palette.secondary[950],
    borderColor: theme.palette.secondary[900],
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: theme.spacing(2),
    boxShadow: "0px 16px 70px rgba(0, 0, 0, 0.50)",
  },
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }: { theme: Theme }) => ({
  maxWidth: "360px",
  color: theme.palette.secondary[100],
  fontSize: theme.typography.title4.fontSize,
  fontWeight: 500,
  paddingBottom: theme.spacing(2),
}));

const DialogContentText = styled(MuiDialogContentText)(
  ({ theme }: { theme: Theme }) => ({
    maxWidth: "360px",
    lineHeight: 1.5,
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(4),
  }),
);

const DialogActions = styled(MuiDialogActions)({
  display: "flex",
  padding: "8px",
});

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    title,
    description,
    open,
    confirmButtonLabel = "Confirm",
    cancelButtonProps,
    confirmButtonProps,
    onConfirm,
    onClose,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>{description}</DialogContentText>
      </MuiDialogContent>
      <DialogActions>
        <Button color="secondary" {...cancelButtonProps} onClick={onClose}>
          Cancel
        </Button>
        <Button {...confirmButtonProps} onClick={onConfirm} autoFocus>
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
