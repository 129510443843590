import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { Icon, Typography } from "../../../../../../../../../ui-v2";

const Container = styled("div")(({ theme }) => ({
  transition:
    "margin 300ms ease-out, width 300ms ease-out, minHeight 300ms ease-out",
  display: "flex",
  gap: 2,
  fontSize: 12,
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0, 1),
  backgroundColor: "transparent",
  borderRadius: 9999,

  svg: {
    color: theme.palette.warning[400],
    marginLeft: -1,
    marginTop: -1,
    marginRight: theme.spacing(1),
  },

  "& .MuiTypography-root": {
    display: "flex",
    color: theme.palette.secondary["200/75"],
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },

  borderWidth: 1,
  borderColor: theme.palette.warning[300],
  borderStyle: "solid",
  animation: "ping 1s cubic-bezier(0, 0, 0.2, 1) 1",
  animationFillMode: "forwards",

  "@keyframes ping": {
    "75%, 100%": {
      borderColor: theme.palette.secondary[700],
    },
  },
}));

export const ConnectionIndicator = () => {
  return (
    <Container>
      <Icon
        size="small"
        icon={icon({
          name: "wifi-slash",
          family: "sharp",
          style: "regular",
        })}
      />
      <Typography variant="mini">Offline</Typography>
    </Container>
  );
};
