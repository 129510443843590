import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  bindMenu,
  bindMenuTrigger,
  Button,
  ButtonProps,
  DataMenu,
  Icon,
  MenuItemData,
  MenuProps,
  useMenuState,
} from "..";

interface DataMenuButtonProps extends ButtonProps {
  items?: MenuItemData[];
  menuProps?: Partial<MenuProps>;
  startOpen?: boolean;
}

const DataMenuButtonWithItems = ({
  items,
  menuProps,
  startOpen,
  ...rest
}: DataMenuButtonProps) => {
  const menuState = useMenuState({ startOpen });

  return (
    <>
      <Button
        endIcon={<Icon icon={solid("caret-down")} />}
        {...rest}
        {...bindMenuTrigger(menuState)}
      />
      <DataMenu
        items={items}
        onCloseMenu={menuState.onClose}
        {...menuProps}
        {...bindMenu(menuState)}
      />
    </>
  );
};

export const DataMenuButton = ({
  items,
  menuProps,
  startOpen,
  ...rest
}: DataMenuButtonProps) => {
  if (items) {
    return (
      <DataMenuButtonWithItems
        items={items}
        menuProps={menuProps}
        startOpen={startOpen}
        {...rest}
      />
    );
  }
  return <Button {...rest} />;
};
