import { InferenceStyleParameters } from "../../../../types";
import { DimensionsType } from "../../constants";

export const DEFAULT_CREATIVITY_REALTIME = 30;
export const MIN_CREATIVITY_REALTIME = 0;
export const MAX_CREATIVITY_REALTIME = 100;

export const REALTIME_FORM_FIELDS = {
  STYLE: "style",
  PROMPT_LANGUAGE: "promptLanguage",
  PROMPT_TEXT: "promptText",
  CREATIVITY: "creativity",
  CANVAS_IMAGE: "canvasImage",
  DIMENSIONS: "dimensions",
} as const;

export const DEFAULT_REALTIME_FORM_VALUES: FormTypeRealtime = {
  [REALTIME_FORM_FIELDS.STYLE]: null,
  [REALTIME_FORM_FIELDS.PROMPT_LANGUAGE]: null,
  [REALTIME_FORM_FIELDS.PROMPT_TEXT]: "",
  [REALTIME_FORM_FIELDS.CREATIVITY]: DEFAULT_CREATIVITY_REALTIME,
  [REALTIME_FORM_FIELDS.CANVAS_IMAGE]: null,
  [REALTIME_FORM_FIELDS.DIMENSIONS]: [1024, 1024],
};

export interface FormTypeRealtime {
  [REALTIME_FORM_FIELDS.STYLE]: InferenceStyleParameters;
  [REALTIME_FORM_FIELDS.PROMPT_LANGUAGE]?: string;
  [REALTIME_FORM_FIELDS.PROMPT_TEXT]?: string;
  [REALTIME_FORM_FIELDS.CREATIVITY]: number;
  [REALTIME_FORM_FIELDS.CANVAS_IMAGE]?: string;
  [REALTIME_FORM_FIELDS.DIMENSIONS]: DimensionsType;
}

export const getDefaultRealtimeCanvasFormValues = () =>
  JSON.parse(JSON.stringify(DEFAULT_REALTIME_FORM_VALUES));
