import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { UseQueryArgs, UseQueryState } from "urql";

import { ErrorWithDataFragment } from "./queries.graphql";
import { useQueryWithResponseHandler } from "./useQueryWithResponseHandler";
import { ResponseData } from "./useUrqlResponseHandler";

const PREPENDED_PAGE_IX = 0;
const FIRST_PAGE_IX = 1;

export interface PaginationConfig {
  initialNumItems: number;
  incrementNumItems: number;
  enableRecentItems?: boolean;
}

export interface PaginationInput {
  before?: string;
  after?: string;
  first?: number;
  last?: number;
}

export interface PageInfo {
  endCursor?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  totalCount?: number;
}

export const EmptyPageInfo: PageInfo = {
  endCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: null,
  totalCount: 0,
};

interface PageState<T> {
  items: T[];
  isFetching: boolean;
  info?: PageInfo;
  error?: ErrorWithDataFragment;
}

// Enable higher-order function type inference (https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref)
declare module "react" {
  function forwardRef<T, P = unknown>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

interface PageLoaderHandle {
  reExecuteQuery: () => void;
}

interface PageLoaderProps<Data, Variables, Item> {
  onPageUpdate: (page: PageState<Item>) => void;
  queryArgs: UseQueryArgs<Variables, Data>;
  extractQueryItems: (result: ResponseData<Data>) => {
    pageInfo: PageInfo;
    items: Array<Item>;
  };
}

export const PageLoaderInner = <Data, Variables, Item>(
  {
    onPageUpdate,
    queryArgs,
    extractQueryItems,
  }: PageLoaderProps<Data, Variables, Item>,
  ref: ForwardedRef<PageLoaderHandle>,
) => {
  const {
    state: query,
    execute: executeQuery,
    data,
    error,
  } = useQueryWithResponseHandler(queryArgs);

  useImperativeHandle(
    ref,
    () => ({
      reExecuteQuery() {
        executeQuery();
      },
    }),
    [executeQuery],
  );

  const isDataReady = (query: UseQueryState<Data, Variables>) =>
    !query.fetching && !query.stale && query.data; // TODO fetch error somehow, possibly with useHandleUrqlResponse

  useEffect(() => {
    if (isDataReady(query) && data) {
      const { pageInfo, items } = extractQueryItems(data);
      onPageUpdate({
        items,
        isFetching: false,
        info: pageInfo,
        error,
      });
    } else {
      onPageUpdate({
        items: [],
        isFetching: true,
        error,
      });
    }
  }, [query]);

  return null;
};

export const PageLoader = forwardRef(PageLoaderInner);

interface PaginationStateProps<Data, Variables, Item> {
  paginationInputs: PaginationInput[];
  onPageUpdate: (index: number, page: PageState<Item>) => void;
  getQueryArgs: (
    paginationInput: PaginationInput,
  ) => UseQueryArgs<Variables, Data>;
  extractQueryItems: (result: ResponseData<Data>) => {
    pageInfo: PageInfo;
    items: Array<Item>;
  };
}

interface PaginationStateHandle {
  refreshRecents: () => void;
}

export const PaginationStateInner = <Data, Variables, Item>(
  {
    paginationInputs,
    onPageUpdate,
    getQueryArgs,
    extractQueryItems,
  }: PaginationStateProps<Data, Variables, Item>,
  ref: ForwardedRef<PaginationStateHandle>,
) => {
  const prependLoaderRef = useRef<PageLoaderHandle>(null);
  useImperativeHandle(
    ref,
    () => ({
      refreshRecents() {
        prependLoaderRef?.current?.reExecuteQuery();
      },
    }),
    [],
  );
  return (
    <>
      {paginationInputs
        .map<[PaginationInput, number]>((paginationInput, i) => [
          paginationInput,
          i,
        ])
        .filter(([paginationInput]) => paginationInput)
        .map(([paginationInput, i]) => (
          <PageLoader<Data, Variables, Item>
            ref={i === PREPENDED_PAGE_IX ? prependLoaderRef : undefined}
            key={"page-" + i}
            onPageUpdate={(page) => onPageUpdate(i, page)}
            queryArgs={getQueryArgs(paginationInput)}
            extractQueryItems={extractQueryItems}
          />
        ))}
    </>
  );
};

export const PaginationState = forwardRef(PaginationStateInner);

const useInfinitePaginationNoStateHolder = <Item,>({
  initialNumItems,
  incrementNumItems,
  enableRecentItems,
}: PaginationConfig): {
  items: Array<Item>;
  paginationInputs: PaginationInput[];
  onPageUpdate: (index: number, page: PageState<Item>) => void;
  reload: () => void;
  fetchMoreItems: () => void;
  isFetching: boolean;
  hasMorePages: boolean;
} => {
  const [paginationInputs, setPaginationInputs] = useState<PaginationInput[]>(
    [],
  );
  const [pages, setPages] = useState<PageState<Item>[]>([]);
  const items = useMemo(
    () => pages.flatMap((page) => page?.items || []),
    [pages],
  );

  const isFetching =
    paginationInputs.length === 0 ||
    paginationInputs.length > pages.length ||
    pages.some((page) => page?.isFetching);

  const hasMorePages = isFetching || pages[pages.length - 1]?.info?.hasNextPage;

  const onPageUpdate = useCallback(
    (index: number, page: PageState<Item>) => {
      if (!page.isFetching) {
        setPages((prev) => {
          const next = [...prev];
          next[index] = page;
          if (
            enableRecentItems &&
            index === FIRST_PAGE_IX &&
            paginationInputs[PREPENDED_PAGE_IX]?.before !==
              page.info?.startCursor
          ) {
            next[0] = undefined;
          }
          return next;
        });
      }
    },
    [setPages, paginationInputs],
  );

  const lastPage = pages[pages.length - 1];
  const fetchMoreItems = useCallback(() => {
    const endCursor = lastPage?.info?.endCursor;
    if (!isFetching && endCursor) {
      setPaginationInputs((prev) => [
        ...prev,
        {
          after: endCursor,
          first: incrementNumItems,
        },
      ]);
    }
  }, [setPaginationInputs, isFetching, lastPage]);

  const reload = useCallback(() => {
    setPaginationInputs([]);
    setPages([]);
  }, [setPages, setPaginationInputs]);

  useEffect(() => {
    if (!paginationInputs?.length) {
      setPaginationInputs([
        undefined,
        {
          first: initialNumItems,
        },
      ]);
    }
  }, [paginationInputs, setPaginationInputs]);

  useEffect(() => {
    const firstPageStart = pages[FIRST_PAGE_IX]?.info?.startCursor;
    if (firstPageStart) {
      setPaginationInputs((prev) => {
        if (
          !enableRecentItems ||
          prev[PREPENDED_PAGE_IX]?.before === firstPageStart
        ) {
          return prev;
        }
        const next = [...prev];
        next[PREPENDED_PAGE_IX] = {
          before: firstPageStart,
        };
        return next;
      });
    }
  }, [enableRecentItems, pages, setPaginationInputs]);

  return {
    items,
    paginationInputs,
    onPageUpdate,
    reload,
    fetchMoreItems,
    isFetching,
    hasMorePages,
  };
};

export const useInfinitePagination = <QueryArgs, Data, Variables, Item>(
  config: PaginationConfig,
  queryArgs: QueryArgs,
  applyPagination: (
    queryArgs: QueryArgs,
    paginationInput: PaginationInput,
  ) => UseQueryArgs<Variables, Data>,
  extractQueryItems: (result: ResponseData<Data>) => {
    pageInfo: PageInfo;
    items: Array<Item>;
  },
): {
  items: Array<Item>;
  reload: () => void;
  fetchMoreItems: () => void;
  refreshRecents: () => void;
  isFetching: boolean;
  hasMorePages: boolean;
  PaginationStateElement: React.JSX.Element;
} => {
  const { paginationInputs, onPageUpdate, reload, ...rest } =
    useInfinitePaginationNoStateHolder<Item>(config);

  const paginationStateRef = useRef<PaginationStateHandle>(null);
  const refreshRecents = useCallback(() => {
    if (!paginationInputs[0]) {
      reload(); // Recents not defined yet, reload state
    } else {
      paginationStateRef?.current?.refreshRecents();
    }
  }, [paginationInputs, reload]);

  // Refresh the hook state before propagating the new args to PaginationState
  // to avoid re-fetching pages of data we do not care about
  const [effectiveQueryArgs, setEffectiveQueryArgs] = useState<QueryArgs>();
  useEffect(() => {
    setEffectiveQueryArgs(queryArgs);
    reload();
  }, [queryArgs, reload, setEffectiveQueryArgs]);

  const getQueryArgs = useCallback(
    (paginationInput) => applyPagination(effectiveQueryArgs, paginationInput),
    [applyPagination, effectiveQueryArgs],
  );

  const PaginationStateElement = (
    <PaginationState
      ref={paginationStateRef}
      paginationInputs={paginationInputs}
      onPageUpdate={onPageUpdate}
      extractQueryItems={extractQueryItems}
      getQueryArgs={getQueryArgs}
    />
  );

  return {
    ...rest,
    reload,
    refreshRecents,
    PaginationStateElement,
  };
};
