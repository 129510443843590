import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Accept } from "react-dropzone";

import { InferenceQuality, InferenceStyleParameters } from "../../types";
import { getAssetURL } from "../../utils/assets";
import { SessionInferenceMode } from "../graphql/schema.graphql";
import { Icon } from "../ui-v2";
import {
  CannyEdgeIcon,
  DepthMapIcon,
  PoseIcon,
  ReferenceImageIcon,
  StyleReferenceIcon,
} from "../ui-v2/icons";
import { FormType3D } from "./forms/3D/constants";
import { FormTypeRealtime } from "./forms/Realtime/constants";
import { FormTypeUpscale } from "./forms/Upscale/constants";

export type PresetType = {
  label: string;
  aspectRatioLabel: string;
  aspectRatio: number;
  dimensions: DimensionsType;
};

export const ACCEPTED_REFERENCE_IMAGE_TYPES: Accept = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/svg": [".svg"],
  "image/webp": [".webp"],
  "image/gif": [".gif"],
};

export const SAVE_VALUES_TO_SERVER_DEBOUNCE_MS = 2000;

export const INFERENCE_FORMS_WIDTH = 240;

export const SESSION_HEADER_HEIGHT = 48;

export const MIN_SEED = 1;
export const MAX_SEED = 1099511627776;
export const DEFAULT_DENSITY = 1;
export const MIN_DENSITY = 1;
export const MAX_DENSITY = 5;
export const MIN_STYLE_WEIGHT = 0;
export const MAX_STYLE_WEIGHT = 100;
export const MIN_REFERENCE_SIMILARITY = 0;
export const MAX_REFERENCE_SIMILARITY = 100;
export const DEFAULT_REFERENCE_SIMILARITY = 50;
export const MIN_PROMPT_STRENGTH = 0;
export const MAX_PROMPT_STRENGTH = 100;
export const MIN_DENOISING_STEPS = 1;
export const MAX_DENOISING_STEPS = 50;
export const MIN_FACE_LIMIT = 200;
export const MAX_FACE_LIMIT = 1000000; // 1 million
export const DEFAULT_FACE_LIMIT = 10000; // 10k

export const REFERENCES_METADATA = {
  INIT: {
    label: "Reference Image",
    icon: <ReferenceImageIcon />,
    isDefault: true,
    incompatibleReferenceTypes: ["COLOR_SKETCH"],
    guidanceFileType: "INIT",
    preprocess: false,
    weight: 0.5,
    isLocalized: true,
    tooltip: "Use this image as a starting point and create variations.",
    tooltipImageSrc: getAssetURL("tooltip_images/variations.jpg"),
    similarityTooltip:
      "Set how similar the generated image should be to this image, where higher percentage means more similar.",
  },
  PROMPT: {
    label: "Style Reference",
    icon: <StyleReferenceIcon />,
    isDefault: true,
    incompatibleReferenceTypes: ["FACE"],
    guidanceFileType: "PROMPT",
    preprocess: false,
    weight: 1,
    isLocalized: false,
    tooltip: "Transfer the style of an image onto your generations.",
    tooltipImageSrc: getAssetURL("tooltip_images/style_transfer.jpg"),
    similarityTooltip:
      "Set how closely the generate image should follow the style of this image, where higher percentage means more closely.",
  },
  COLOR_SKETCH: {
    label: "Color Sketch",
    icon: <Icon icon={regular("palette")} />,
    isDefault: true,
    incompatibleReferenceTypes: ["PROMPT", "CANNY", "SCRIBBLE"],
    guidanceFileType: "COLOR_SKETCH",
    preprocess: false,
    weight: 1,
    isLocalized: true,
    tooltip: "Upload or draw a color sketch to guide your image generation.",
    tooltipImageSrc: getAssetURL("tooltip_images/sketch_color.jpg"),
    similarityTooltip:
      "Set how closely the generate image should follow this sketch, where higher percentage means more closely.",
  },
  SCRIBBLE: {
    label: "B&W Sketch",
    icon: <Icon icon={regular("scribble")} />,
    isDefault: false,
    incompatibleReferenceTypes: ["COLOR_SKETCH", "CANNY"],
    guidanceFileType: "SCRIBBLE",
    preprocess: false,
    weight: 1,
    isLocalized: true,
    tooltip:
      "Upload or draw a black and white sketch to guide your image generation.",
    tooltipImageSrc: getAssetURL("tooltip_images/sketch_b&w.jpg"),
    similarityTooltip:
      "Set how closely the generate image should follow this sketch, where higher percentage means more closely.",
  },
  FACE: {
    label: "Character Face",
    icon: <Icon icon={regular("face-laugh")} />,
    isDefault: false,
    incompatibleReferenceTypes: ["PROMPT"],
    guidanceFileType: "FACE",
    preprocess: false,
    isLocalized: false,
    weight: 1,
    tooltip:
      "Upload a close-up image of a character's face to generate images with this face.",
    tooltipImageSrc: getAssetURL("tooltip_images/character_face.jpg"),
    similarityTooltip:
      "Set how much the generate image should resemble this face, where higher percentage means higher resemblance.",
  },
  POSE: {
    label: "Character Pose",
    icon: <PoseIcon />,
    isDefault: false,
    incompatibleReferenceTypes: [],
    guidanceFileType: "POSE",
    preprocess: false,
    weight: 1,
    isLocalized: true,
    tooltip:
      "Generate an image in a specific human pose that matches the pose of the person in your image.",
    tooltipImageSrc: getAssetURL("tooltip_images/character_pose.jpg"),
    similarityTooltip:
      "Set how closely the generate image should match this pose, where higher percentage means more closely.",
  },
  DEPTH: {
    label: "Structure",
    icon: <DepthMapIcon />,
    isDefault: false,
    incompatibleReferenceTypes: [],
    guidanceFileType: "DEPTH",
    preprocess: true,
    weight: 1,
    isLocalized: true,
    tooltip:
      "Change the appearance of your asset while maintaining its base structure.",
    tooltipImageSrc: getAssetURL("tooltip_images/depth_map.jpg"),
    similarityTooltip:
      "Set how similar the generated image should be to this image, where higher percentage means more similar.",
  },
  CANNY: {
    label: "Canny Edge",
    icon: <CannyEdgeIcon />,
    isDefault: false,
    incompatibleReferenceTypes: ["COLOR_SKETCH", "SCRIBBLE"],
    guidanceFileType: "CANNY",
    preprocess: true,
    weight: 1,
    isLocalized: true,
    tooltip: "Generate an image following the canny edges of your image.",
    tooltipImageSrc: getAssetURL("tooltip_images/canny.jpg"),
    similarityTooltip:
      "Set how similar the generated image should be to this image, where higher percentage means more similar.",
  },
} as const;

export const MIN_UPSCALE_FACTOR = 2;
export const MAX_UPSCALE_FACTOR = 8;
export const MAX_UPSCALE_DIMENSION = 4096;
export const MIN_UPSCALE_RESEMBLANCE = 0;
export const MAX_UPSCALE_RESEMBLANCE = 100;
export const MIN_UPSCALE_CREATIVITY = 0;
export const MAX_UPSCALE_CREATIVITY = 100;

export type InferenceFormsContexts = "canvas" | "session";

export type ReferenceTypesType = keyof typeof REFERENCES_METADATA;

export type ReferenceImageType = {
  width: number;
  height: number;
  src: string;
  driveFileId?: string;
};

export type CanvasImageType = {
  width: number;
  height: number;
  src: string;
  maskImageUrl?: string;
};

export type ReferenceType = {
  type: ReferenceTypesType;
  image: ReferenceImageType;
  similarity: number;
};

export type CanvasSelectionType = {
  type: ReferenceTypesType;
  image: CanvasImageType;
  similarity: number;
  density: number;
};

export const FORM_FIELDS_2D = {
  STYLE: "style",
  PROMPT_LANGUAGE: "promptLanguage",
  PROMPT_TEXT: "promptText",
  NEGATIVE_PROMPT_TEXT: "negativePromptText",
  PERFORMANCE: "performance",
  SEED: "seed",
  PROMPT_STRENGTH: "promptStrength",
  DENOISING_STEPS: "denoisingSteps",
  TRANSPARENCY: "transparency",
  TILEABILITY: "tileability",
  DIMENSIONS: "dimensions",
  REFERENCES: "references",
  DENSITY: "density",
  CANVAS_SELECTION: "canvasSelection",
} as const;

// Create a type that includes all the values from FORM_FIELDS_2D
export type FieldValuesType =
  (typeof FORM_FIELDS_2D)[keyof typeof FORM_FIELDS_2D];

export type DimensionsType = [number, number];

export type PerformanceType = InferenceQuality;
export const PERFORMANCE_OPTIONS = ["LOW", "MEDIUM", "HIGH"] as const;

export const PerformanceOptionToNameMap = new Map<PerformanceType, string>([
  ["LOW", "Fast, Low Quality"],
  ["MEDIUM", "Balanced Quality and Speed"],
  ["HIGH", "Slower, Best Quality"],
]);

export function getDefaultReference(
  params: Omit<ReferenceType, "similarity">,
): ReferenceType;

export function getDefaultReference(
  params: Omit<ReferenceType, "image" | "similarity">,
): ReferenceType;

export function getDefaultReference(params: ReferenceType): ReferenceType;

export function getDefaultReference(params: ReferenceType) {
  const {
    type,
    image = null,
    similarity = DEFAULT_REFERENCE_SIMILARITY,
  } = params;

  return { type, image, similarity };
}

export interface FormType2D {
  [FORM_FIELDS_2D.STYLE]: InferenceStyleParameters;
  [FORM_FIELDS_2D.PROMPT_LANGUAGE]: string;
  [FORM_FIELDS_2D.PROMPT_TEXT]: string;
  [FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT]: string;
  [FORM_FIELDS_2D.PERFORMANCE]: InferenceQuality;
  [FORM_FIELDS_2D.SEED]?: number;
  [FORM_FIELDS_2D.PROMPT_STRENGTH]: number;
  [FORM_FIELDS_2D.DENOISING_STEPS]: number;
  [FORM_FIELDS_2D.TRANSPARENCY]: boolean;
  [FORM_FIELDS_2D.TILEABILITY]: boolean;
  [FORM_FIELDS_2D.DIMENSIONS]: DimensionsType;
  [FORM_FIELDS_2D.REFERENCES]: ReferenceType[];
  [FORM_FIELDS_2D.CANVAS_SELECTION]?: CanvasSelectionType;
}

export const DEFAULT_2D_FORM_VALUES: FormType2D = {
  [FORM_FIELDS_2D.STYLE]: null,
  [FORM_FIELDS_2D.PROMPT_LANGUAGE]: null,
  [FORM_FIELDS_2D.PROMPT_TEXT]: "",
  [FORM_FIELDS_2D.NEGATIVE_PROMPT_TEXT]: "",
  [FORM_FIELDS_2D.PERFORMANCE]: "MEDIUM",
  [FORM_FIELDS_2D.SEED]: null,
  [FORM_FIELDS_2D.PROMPT_STRENGTH]: 50,
  [FORM_FIELDS_2D.DENOISING_STEPS]: 10,
  [FORM_FIELDS_2D.TRANSPARENCY]: false,
  [FORM_FIELDS_2D.TILEABILITY]: false,
  [FORM_FIELDS_2D.DIMENSIONS]: [1024, 1024],
  [FORM_FIELDS_2D.REFERENCES]: [],
  [FORM_FIELDS_2D.CANVAS_SELECTION]: {
    type: "INIT",
    image: null,
    similarity: DEFAULT_REFERENCE_SIMILARITY,
    density: DEFAULT_DENSITY,
  },
};

export const getDefaultForm2DValues = () =>
  JSON.parse(JSON.stringify(DEFAULT_2D_FORM_VALUES));

// For passing data through routes in new inference form
export type InferenceFormNavigationState = {
  mode: SessionInferenceMode;
  form?: Partial<FormTypeUpscale> | Partial<FormType2D>;
};

export type FormType =
  | FormType2D
  | FormTypeUpscale
  | FormTypeRealtime
  | FormType3D;
