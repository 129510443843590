import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Icon, Tooltip } from "../../..";
import { IndicatorContainer } from "../IndicatorContainer/IndicatorContainer";

export const NotOptimizedIndicator = ({ tooltip }: { tooltip?: string }) => (
  <Tooltip title={tooltip || "This style is not optimized for this forge mode"}>
    <IndicatorContainer severity="warning">
      <Icon size="medium" icon={regular("hourglass")} />
    </IndicatorContainer>
  </Tooltip>
);
