import { createSvgIcon } from "@mui/material";

export const CanvasIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3177 2.83565C19.0572 2.57524 18.6666 2.57524 18.4322 2.83565L17.8854 3.38253L19.0833 4.58045L19.6302 4.00753C19.8645 3.77315 19.8645 3.38253 19.6302 3.12211L19.3177 2.83565ZM13.8489 7.44503C13.7708 7.5492 13.7187 7.65336 13.6927 7.75753L13.4322 9.05961L14.7083 8.7992C14.8385 8.77315 14.9427 8.69503 15.0208 8.6169L18.1979 5.43982L17 4.26795L13.8489 7.44503ZM17.5468 1.95024C18.276 1.22107 19.4479 1.19503 20.2031 1.95024L20.5156 2.26274C21.2447 2.9919 21.2447 4.16378 20.5156 4.89295L15.9062 9.50232C15.6458 9.76274 15.3333 9.94503 14.9687 10.0232L12.7291 10.4659C12.5468 10.5179 12.3125 10.4659 12.1822 10.3096C12.026 10.1534 11.9479 9.94503 12 9.7367L12.4687 7.49711C12.5468 7.15857 12.7291 6.82003 12.9635 6.58565L17.5468 1.95024Z"
      fill="currentColor"
    />
    <path
      d="M6.62036 3.25H7.55786C7.87036 3.25 8.18286 3.5625 8.18286 3.875C8.18286 4.22656 7.87036 4.5 7.55786 4.5H6.62036C5.40942 4.5 4.43286 5.51562 4.43286 6.6875V7.625C4.43286 7.97656 4.12036 8.25 3.80786 8.25C3.4563 8.25 3.18286 7.97656 3.18286 7.625V6.6875C3.18286 4.8125 4.7063 3.25 6.62036 3.25ZM3.80786 6.5C4.12036 6.5 4.43286 6.8125 4.43286 7.125V16.875C4.43286 17.2266 4.12036 17.5 3.80786 17.5C3.4563 17.5 3.18286 17.2266 3.18286 16.875V7.125C3.18286 6.8125 3.4563 6.5 3.80786 6.5ZM20.0579 8.5C20.3704 8.5 20.6829 8.8125 20.6829 9.125V16.875C20.6829 17.2266 20.3704 17.5 20.0579 17.5C19.7063 17.5 19.4329 17.2266 19.4329 16.875V9.125C19.4329 8.8125 19.7063 8.5 20.0579 8.5ZM20.6829 16.375V17.3125C20.6829 19.2266 19.1204 20.75 17.2454 20.75H16.3079C15.9563 20.75 15.6829 20.4766 15.6829 20.125C15.6829 19.8125 15.9563 19.5 16.3079 19.5H17.2454C18.4172 19.5 19.4329 18.5234 19.4329 17.3125V16.375C19.4329 16.0625 19.7063 15.75 20.0579 15.75C20.3704 15.75 20.6829 16.0625 20.6829 16.375ZM4.43286 16.375V17.3125C4.43286 18.5234 5.40942 19.5 6.62036 19.5H7.55786C7.87036 19.5 8.18286 19.8125 8.18286 20.125C8.18286 20.4766 7.87036 20.75 7.55786 20.75H6.62036C4.7063 20.75 3.18286 19.2266 3.18286 17.3125V16.375C3.18286 16.0625 3.4563 15.75 3.80786 15.75C4.12036 15.75 4.43286 16.0625 4.43286 16.375ZM7.05786 20.75C6.7063 20.75 6.43286 20.4766 6.43286 20.125C6.43286 19.8125 6.7063 19.5 7.05786 19.5H16.8079C17.1204 19.5 17.4329 19.8125 17.4329 20.125C17.4329 20.4766 17.1204 20.75 16.8079 20.75H7.05786ZM6.43286 3.875C6.43286 3.5625 6.7063 3.25 7.05786 3.25H11.8079C12.1204 3.25 12.4329 3.5625 12.4329 3.875C12.4329 4.22656 12.1204 4.5 11.8079 4.5H7.05786C6.7063 4.5 6.43286 4.22656 6.43286 3.875Z"
      fill="currentColor"
    />
  </svg>,
  "CanvasIcon",
);
