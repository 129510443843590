import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import pluralize from "pluralize";

import { useBasicData } from "../../../../../../../../../../hooks";
import config from "../../../../../../../../../../utils/config";
import {
  Alert,
  Icon,
  Link,
  Typography,
} from "../../../../../../../../../ui-v2";

export const AppWarnings = () => {
  const { workspace, error } = useBasicData();

  if (error || !workspace) {
    return null;
  }

  const workspacePlan =
    config.OFFERED_PLANS.find((plan) => workspace?.planTier === plan.tier) ??
    null;

  const messages = [];
  if (workspace.billingInfo?.periodEndAt) {
    // calculate days left
    const now = new Date();
    const end = new Date(workspace.billingInfo.periodEndAt);
    const diff = end.getTime() - now.getTime();
    const daysLeft = Math.ceil(diff / (1000 * 3600 * 24));
    if (daysLeft >= 0) {
      if (workspace.billingInfo?.isCancelled) {
        messages.push(
          <Typography variant="caption" key="cancelled">
            <span>
              Your plan is cancelled.
              <br /> You will have access until{" "}
            </span>
            <strong>{end.toLocaleDateString()}</strong>
            <span> (</span>
            <strong>{daysLeft}</strong>
            <span> days).</span>
            <br />
            <br />
            <span>Click </span>
            <Link
              href={`/${workspace.name}/settings/billing`}
              sx={{ fontWeight: "bold" }}
            >
              here
            </Link>
            <span> to update your plan.</span>
          </Typography>,
        );
      } else if (workspace.billingInfo?.isTrial) {
        messages.push(
          <Typography variant="caption" key="trial">
            <span>Your free </span>
            <strong>{workspacePlan?.name || ""}</strong>
            <span> trial will end in </span>
            <strong>{daysLeft}</strong>
            <span> {pluralize("day", daysLeft)}</span>
            {workspace.billingInfo?.paymentMethodSummary ? (
              <span>
                {" "}
                and we will start charging your account at that time. You can
                manage your plan{" "}
              </span>
            ) : (
              <span>
                {" "}
                and we will cancel your plan at that time. You can add a payment
                method to keep your plan{" "}
              </span>
            )}
            <Link
              href={`/${workspace.name}/settings/billing`}
              sx={{ fontWeight: "bold" }}
            >
              here
            </Link>
            <span>.</span>
          </Typography>,
        );
      }
    }
  }

  if (messages.length === 0) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      icon={<Icon icon={solid("exclamation-circle")} size="small" />}
    >
      {messages}
    </Alert>
  );
};
