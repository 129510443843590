import { createSvgIcon } from "@mui/material";

export const StyleReferenceIcon = createSvgIcon(
  <svg
    width="14"
    height="14"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.65497 8.99997C2.65497 5.49531 5.49531 2.65497 8.99997 2.65497C11.1919 2.65497 13.124 3.76602 14.2638 5.45577L16.5689 7.84787C16.0133 4.16644 12.8365 1.34497 8.99997 1.34497C4.77182 1.34497 1.34497 4.77182 1.34497 8.99997C1.34497 13.2281 4.77182 16.655 8.99997 16.655H9.27128L11.6763 14.25L10.8823 13.456C10.7291 13.3028 10.6211 13.1117 10.5679 12.905H9.23782C9.29725 13.3999 9.50323 13.8671 9.8286 14.245L8.73413 15.3395C5.3528 15.2002 2.65497 12.4156 2.65497 8.99997Z"
      fill="currentColor"
    />
    <path
      d="M8.1499 11.4049H14.8318L13.0584 13.2454L14.0017 14.1543L16.6516 11.4043L16.6499 11.4027V10.0971L16.6516 10.0955L14.0017 7.34546L13.0584 8.25444L14.8318 10.0949H8.1499V11.4049Z"
      fill="currentColor"
    />
    <path
      d="M9.8199 5.90324C9.62455 6.09859 9.3596 6.20833 9.08333 6.20833C8.80707 6.20833 8.54211 6.09859 8.34676 5.90324C8.15141 5.70789 8.04167 5.44293 8.04167 5.16667C8.04167 4.8904 8.15141 4.62545 8.34676 4.4301C8.54211 4.23475 8.80707 4.125 9.08333 4.125C9.3596 4.125 9.62455 4.23475 9.8199 4.4301C10.0153 4.62545 10.125 4.8904 10.125 5.16667C10.125 5.44293 10.0153 5.70789 9.8199 5.90324Z"
      fill="currentColor"
    />
    <path
      d="M6.6949 5.47176C6.89025 5.66711 7 5.93207 7 6.20833C7 6.4846 6.89025 6.74955 6.6949 6.9449C6.49955 7.14025 6.2346 7.25 5.95833 7.25C5.68207 7.25 5.41711 7.14025 5.22176 6.9449C5.02641 6.74955 4.91667 6.4846 4.91667 6.20833C4.91667 5.93207 5.02641 5.66711 5.22176 5.47176C5.41711 5.27641 5.68207 5.16667 5.95833 5.16667C6.2346 5.16667 6.49955 5.27641 6.6949 5.47176Z"
      fill="currentColor"
    />
    <path
      d="M5.65324 8.59676C5.84859 8.79211 5.95833 9.05707 5.95833 9.33333C5.95833 9.6096 5.84859 9.87455 5.65324 10.0699C5.45789 10.2653 5.19293 10.375 4.91667 10.375C4.6404 10.375 4.37545 10.2653 4.1801 10.0699C3.98475 9.87455 3.875 9.6096 3.875 9.33333C3.875 9.05707 3.98475 8.79211 4.1801 8.59676C4.37545 8.40141 4.6404 8.29167 4.91667 8.29167C5.19293 8.29167 5.45789 8.40141 5.65324 8.59676Z"
      fill="currentColor"
    />
    <path
      d="M12.9449 5.47176C13.1403 5.66711 13.25 5.93207 13.25 6.20833C13.25 6.4846 13.1403 6.74955 12.9449 6.9449C12.7496 7.14025 12.4846 7.25 12.2083 7.25C11.9321 7.25 11.6671 7.14025 11.4718 6.9449C11.2764 6.74955 11.1667 6.4846 11.1667 6.20833C11.1667 5.93207 11.2764 5.66711 11.4718 5.47176C11.6671 5.27641 11.9321 5.16667 12.2083 5.16667C12.4846 5.16667 12.7496 5.27641 12.9449 5.47176Z"
      fill="currentColor"
    />
  </svg>,
  "StyleReferenceIcon",
);
