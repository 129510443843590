import { useAuth0 } from "@auth0/auth0-react";
import { Stack, styled } from "@mui/system";
import { PropsWithChildren } from "react";
import { Outlet, useMatch } from "react-router-dom";

import { PrimaryNavigation } from "../nav/components/PrimaryNavigation/PrimaryNavigation";
import { SessionNavigation } from "../nav/components/SessionNavigation/SessionNavigation";
import { SESSION_NAVIGATION_WIDTH } from "../nav/constants";
import TeamNoticesWrapper from "../notices/TeamNoticesWrapper";

const Content = styled("main")(({ theme }) => ({
  position: "relative",
  width: "100%",
  margin: theme.spacing(2),
  borderRadius: 5,
  border: "1px solid #2C2D3A",
  backgroundColor: "#191A22",
  overflow: "auto",
  height: CSS.supports("height", "100dvh")
    ? `calc(100dvh - ${theme.spacing(4)})`
    : `calc(100vh - ${theme.spacing(4)})`,
}));

const SessionContent = styled(Content)({
  paddingLeft: SESSION_NAVIGATION_WIDTH,
});

const LayoutContent = (props) => (
  <Stack sx={{ overflow: "hidden" }}>
    <Stack direction="row">{props.children}</Stack>
  </Stack>
);

export default function Layout({ children = <Outlet /> }: PropsWithChildren) {
  const sessionMatch = useMatch("/:workspace/session/:sessionId");
  const isSessionContext = !!sessionMatch;
  const { isAuthenticated } = useAuth0();

  const content = (
    <LayoutContent>
      <PrimaryNavigation />
      {isSessionContext ? (
        <SessionContent id="page-content">
          <SessionNavigation />
          {children}
        </SessionContent>
      ) : (
        <Content id="page-content">{children}</Content>
      )}
    </LayoutContent>
  );

  return isAuthenticated ? (
    <TeamNoticesWrapper>{content}</TeamNoticesWrapper>
  ) : (
    content
  );
}
