import { useCallback } from "react";
import { generatePath } from "react-router-dom";
import { useMutation } from "urql";

import { Events } from "../constants/events";
import { SessionInferenceFormState } from "../types";
import {
  BasicDataSimpleWorkspaceFragment,
  CreateSession,
} from "./queries.graphql";
import { useMixpanel } from "./useMixpanel";
import useNavigate from "./useNavigate";
import { useUrqlResponseHandler } from "./useUrqlResponseHandler";

export interface CreateSessionParams {
  workspace: BasicDataSimpleWorkspaceFragment;
  sessionName: string;
  inferenceFormState: SessionInferenceFormState;
}

export function useCreateSession() {
  const mixpanel = useMixpanel();
  const navigate = useNavigate();

  const [, createSession] = useMutation(CreateSession);
  const { getResponseFromState } = useUrqlResponseHandler();

  return useCallback(
    async ({
      workspace,
      sessionName,
      inferenceFormState,
    }: CreateSessionParams) => {
      mixpanel.track(Events.SESSION_CREATE);

      const response = await createSession({
        createSessionInput: {
          name: sessionName,
          workspaceId: workspace.id,
          inferenceFormState,
        },
      });

      const {
        data: { session },
      } = getResponseFromState({
        state: response,
        errorMessage:
          "There was an error creating your session. Please try again.",
      });

      if (session) {
        const newSessionId = session.id;
        const newUrl = generatePath(`/${workspace.name}/session/:sessionId`, {
          sessionId: newSessionId,
        });
        navigate(newUrl, {
          replace: true,
        });
        return session;
      }
    },
    [mixpanel, navigate],
  );
}
