import { styled } from "@mui/system";
import { ReactNode } from "react";

import { Tooltip, TooltipProps } from "../";

interface DisabledAreaProps extends Omit<TooltipProps, "children"> {
  disabled: boolean;
  children: ReactNode;
}

const DisabledContainer = styled("div")({
  cursor: "not-allowed",
  position: "relative",
  zIndex: 0,
});

const OverlayArea = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,
});

export const DisabledArea = (props: DisabledAreaProps) => {
  const { disabled, children, ...rest } = props;

  if (!disabled) {
    return children;
  }

  return (
    <Tooltip {...rest}>
      <DisabledContainer>
        <OverlayArea />
        {children}
      </DisabledContainer>
    </Tooltip>
  );
};
