import { useLocation } from "react-router-dom";

// getNextFromPath is a helper function that extracts the next parameter from a URL path
// it's useful to be able to get the next parameter recursively in case it's nested
export function getNextFromPath(path: string = ""): string {
  const urlParams = new URLSearchParams(path.split("?")[1] || "");
  if (urlParams.has("next")) {
    return getNextFromPath(decodeURIComponent(urlParams.get("next") || ""));
  }
  if (urlParams.has("code")) {
    return "";
  }
  return encodeURIComponent(path);
}

export default function useNextParam(): string {
  const location = useLocation();
  return getNextFromPath(
    `${location.pathname}${location.search}${location.hash}`,
  );
}
