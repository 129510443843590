import { Divider, Menu, MenuProps } from "..";
import { DataMenuItem } from "./DataMenuItem";
import { MenuItemData } from "./types";

interface DataMenuProps extends MenuProps {
  items: MenuItemData[];
  onCloseMenu?: () => void;
}

export const DataMenu = ({ items, onCloseMenu, ...rest }: DataMenuProps) => {
  let lastGroup = items?.[0]?.group;
  return (
    <Menu {...rest}>
      {items.reduce((acc, item) => {
        const next = [...acc];
        if (lastGroup !== item.group) {
          next.push(<Divider key={`divider-${item.group}`} />);
        }
        lastGroup = item.group;
        next.push(
          <DataMenuItem
            key={item.name}
            data={item}
            onCloseMenu={onCloseMenu}
          />,
        );
        return next;
      }, [])}
    </Menu>
  );
};
