import { cloneDeep, isEqual } from "lodash";
import { useEffect, useRef } from "react";

interface UseOnValueChangedOptions {
  runOnFirstRender?: boolean;
}

const DEFAULT_OPTIONS: UseOnValueChangedOptions = {
  runOnFirstRender: false,
};

const useOnValueChanged = (
  value: any,
  onChanged: () => void,
  options: UseOnValueChangedOptions = DEFAULT_OPTIONS,
) => {
  const { runOnFirstRender = false } = options;
  const valueRef = useRef(cloneDeep(value));

  if (!isEqual(valueRef.current, value)) {
    valueRef.current = cloneDeep(value);
    onChanged();
  }

  useEffect(() => {
    if (runOnFirstRender) {
      onChanged();
    }
  }, []);
};

export default useOnValueChanged;
