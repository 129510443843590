import { styled } from "@mui/system";

interface YoutubeEmbedProps {
  id: string;
  title: string;
}

const Container = styled("div")({
  overflow: "hidden",
  paddingBottom: "56.25%",
  position: "relative",
  height: 0,
});

const Iframe = styled("iframe")({
  position: "absolute",
  left: 0,
  top: 0,
  height: "100%",
  width: "100%",
});

export const YoutubeEmbed = (props: YoutubeEmbedProps) => {
  const { id, title } = props;
  return (
    <Container>
      <Iframe
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      />
    </Container>
  );
};
