import { CircularProgressProps } from "@mui/material";
import { styled } from "@mui/system";

import { CircularProgress } from "../index";

const Container = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ContainerLoader = (props: CircularProgressProps) => {
  const { ...rest } = props;
  return (
    <Container>
      <CircularProgress {...rest} />
    </Container>
  );
};
