import { styled } from "@mui/system";
import { Link } from "react-router-dom";

import { Typography } from "../../../../../../../../../ui-v2";

interface SessionItemProps {
  to: string;
  name?: string;
  iconUrl?: string;
}

const Container = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 24,
  gap: theme.spacing(2),
  borderRadius: 4,
  boxShadow: "inset 0 0 0 0.5px transparent",
  padding: theme.spacing(1, 1.5),
  marginBottom: theme.spacing(1),

  "&:last-of-type": {
    marginBottom: 0,
  },

  "&:hover": {
    backgroundColor: theme.palette.secondary[900],
    boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
  },
}));

const SessionIcon = styled("img")(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  backgroundColor: theme.palette.secondary["300"],
}));

export const SessionItem = (props: SessionItemProps) => {
  const { to, name, iconUrl } = props;
  return (
    <Container to={to}>
      <SessionIcon src={iconUrl} alt={name} />
      <Typography variant="small" noWrap>
        {name}
      </Typography>
    </Container>
  );
};
