import { Step as MuiStep, StepOwnProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface StepProps extends StepOwnProps {}

const StyledStep = styled(MuiStep)<StepProps>(({ theme }) => {
  const { palette } = theme;
  const styles = {
    borderTop: `2px solid ${palette.secondary[600]}`,
    "&.Mui-completed": {
      borderTop: `2px solid ${palette.success[600]}`,
    },
  };

  return styles;
});

export const Step = React.forwardRef<HTMLDivElement, StepProps>(
  ({ ...rest }: StepProps, ref) => {
    return <StyledStep {...rest} ref={ref} />;
  },
);
