import { matchRoutes, RouteObject } from "react-router-dom";

export const parameterizePathname = (
  routes: RouteObject[],
  location: Partial<Location>,
) => {
  let { pathname } = location;
  const [{ params }] = matchRoutes(routes, location);

  for (const param of Object.values(params)) {
    pathname = pathname.replace(encodeURI(param), "*");
  }

  return pathname;
};
