import {
  BrowserTracing,
  init,
  reactRouterV6Instrumentation,
} from "@sentry/react";
import { PropsWithChildren, useEffect, useState } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import config from "../../utils/config";
import { fetchWithRetry } from "../../utils/fetch-retry";
import Error500 from "../errors/Error500";

export default function ConfigChecker({
  children = <Outlet />,
}: PropsWithChildren) {
  const [invalid, setInvalid] = useState(config.AUTH0_DOMAIN === "");

  const getLayerConfig = () => {
    fetchWithRetry("/backend/config/web.json").then((response) => {
      if (response.ok) {
        response.json().then((newConfig) => {
          Object.entries(newConfig).forEach(([key, value]) => {
            config[key] = value;
          });
          if (config.AUTH0_DOMAIN !== "") {
            setInvalid(false);
            return;
          }
          // then keep trying after a delay
          setTimeout(getLayerConfig, 5000);
        });
      }
    });
  };

  if (invalid) {
    setTimeout(getLayerConfig, 5000);
    return <Error500 />;
  }

  if (config.SENTRY_DSN !== "") {
    init({
      dsn: config.SENTRY_DSN,
      environment: config.IS_PRODUCTION ? "production" : "development",
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }

  return <>{children}</>;
}
