const sleep = (time: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, time));

/**
 * A wrapper function for fetch with retry logic.
 * @param {string} url - The URL to fetch.
 * @param {object} options - Fetch options (method, headers, body, etc.).
 * @param {number} retries - Number of times to retry the request.
 * @param {number} delay - Delay between retries in milliseconds.
 * @returns {Promise<Response>} - The fetch response.
 */
export async function fetchWithRetry(
  url: string,
  options: object = {},
  retries: number = 3,
  delay: number = 1000,
): Promise<Response> {
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      // If the response is not ok, throw an error to trigger a retry
      throw new Error(`Fetch failed with status: ${response.status}`);
    }

    return response; // Return the successful response
  } catch (error: any) {
    if (error.name === "AbortError") {
      // If the request was aborted, re-throw the original AbortError without retry
      throw error;
    }

    if (retries > 0) {
      // Wait for the delay before retrying
      await sleep(delay);
      return fetchWithRetry(url, options, retries - 1, delay); // Recursive retry
    } else {
      // If no retries left, throw the error
      throw error;
    }
  }
}
