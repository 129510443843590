import { useEffect, useMemo } from "react";
import { AnyVariables } from "urql";

import {
  GetResponseFromStateArgs,
  useUrqlResponseHandler,
} from "./useUrqlResponseHandler";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface UseHandleUrqlResponseArgs<
  Data extends { [key in string]?: any },
  Variables extends AnyVariables = AnyVariables,
> extends Omit<
    GetResponseFromStateArgs<Data, Variables>,
    "preventDuplicateErrorMessages"
  > {}

export function useHandleUrqlResponse<
  Data extends { [key in string]?: any },
  Variables extends AnyVariables = AnyVariables,
>(args: UseHandleUrqlResponseArgs<Data, Variables>) {
  const { getResponseFromState, resetErrorHandled } = useUrqlResponseHandler();

  const response = useMemo(
    () =>
      getResponseFromState({
        ...args,
        preventDuplicateErrorMessages: true,
      }),
    [args.state.data, args.state.error, getResponseFromState],
  );

  useEffect(() => {
    resetErrorHandled();
  }, [args.state.operation]);

  return response;
}
