import { Box } from "@mui/system";

import { PrimaryNavigationContainer } from "./components/PrimaryNavigationContainer/PrimaryNavigationContainer";
import { PrimaryNavigationFooter } from "./components/PrimaryNavigationFooter/PrimaryNavigationFooter";
import { PrimaryNavigationHeader } from "./components/PrimaryNavigationHeader/PrimaryNavigationHeader";
import { PrimaryNavigationLinks } from "./components/PrimaryNavigationLinks/PrimaryNavigationLinks";
import { RecentSessions } from "./components/RecentSessions/RecentSessions";

export const PrimaryNavigationContent = () => {
  return (
    <PrimaryNavigationContainer>
      <Box sx={{ mb: 2 }}>
        <PrimaryNavigationHeader />
      </Box>
      <Box sx={{ mb: 3 }}>
        <PrimaryNavigationLinks />
      </Box>
      <RecentSessions />
      <PrimaryNavigationFooter />
    </PrimaryNavigationContainer>
  );
};
