import { Stepper as MuiStepper, StepperOwnProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins";
import React from "react";

interface StepperProps extends StepperOwnProps {
  totalSteps?: number;
  fullWidth?: boolean;
  labelOrientation?: "horizontal" | "vertical";
}

const extraProps: PropertyKey[] = [
  "totalSteps",
  "fullWidth",
  "labelOrientation",
];

const StyledStepper = styled(MuiStepper, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<StepperProps>(({
  theme,
  totalSteps,
  fullWidth = false,
  orientation = "horizontal",
  labelOrientation = "vertical",
}) => {
  const { spacing } = theme;

  const styles: CSSProperties = {
    ...(orientation === "horizontal" && {
      gap: spacing(1),
      ...(!!totalSteps && {
        display: "grid",
        gridTemplateColumns: `repeat(${totalSteps}, 1fr)`,
        columnGap: spacing(2),
      }),
      ...(fullWidth && {
        width: "100%",
      }),
    }),
  };

  styles["& .MuiStep-root"] = {
    ...(orientation === "horizontal" && {
      display: "flex",
      ...(fullWidth
        ? {
            width: "100%",
          }
        : {
            width: "fit-content",
          }),
    }),
  };

  styles["& .MuiStepButton-root"] = {
    ...(labelOrientation === "vertical" && {
      flexDirection: "column",
      alignItems: "start",
      rowGap: spacing(0.25),
    }),
  };

  styles["& .MuiStepLabel-root"] = {
    ...(labelOrientation === "vertical" && {
      flexDirection: "column",
      alignItems: "start",
      rowGap: spacing(0.25),
    }),
  };

  return styles;
});

export const Stepper = React.forwardRef<HTMLDivElement, StepperProps>(
  (props: StepperProps, ref) => {
    return <StyledStepper {...props} ref={ref} connector={null} />;
  },
);
