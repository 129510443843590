import { createSvgIcon } from "@mui/material";

export const TransparencyIcon = createSvgIcon(
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.75"
      width="27.5"
      height="27.5"
      rx="3.75"
      stroke="#373948"
      strokeWidth="0.5"
    />
    <g clipPath="url(#clip0_493_2066)">
      <path
        d="M4.67578 5.75391V24.4023H23.3242V5.75391H4.67578ZM2.13281 3.21094H4.67578H23.3242H25.8672V5.75391V24.4023V26.9453H23.3242H4.67578H2.13281V24.4023V5.75391V3.21094ZM8.91406 6.60156V9.99219H12.3047V6.60156H15.6953V9.99219H19.0859V6.60156H22.4766V9.99219H19.0859V13.3828H22.4766V16.7734H19.0859V20.1641H22.4766V23.5547H19.0859V20.1641H15.6953V23.5547H12.3047V20.1641H8.91406V23.5547H5.52344V20.1641H8.91406V16.7734H5.52344V13.3828H8.91406V9.99219H5.52344V6.60156H8.91406ZM12.3047 13.3828H15.6953V9.99219H12.3047V13.3828ZM12.3047 16.7734V13.3828H8.91406V16.7734H12.3047ZM15.6953 16.7734H12.3047V20.1641H15.6953V16.7734ZM15.6953 16.7734H19.0859V13.3828H15.6953V16.7734Z"
        fill="#858699"
      />
    </g>
    <defs>
      <clipPath id="clip0_493_2066">
        <rect x="6" y="7" width="16" height="16" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "TransparencyIcon",
);
