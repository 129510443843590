import { createSvgIcon } from "@mui/material";

export const PoseIcon = createSvgIcon(
  <svg
    width="14"
    height="14"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.83579 1.58579C7.46071 1.96086 7.25 2.46957 7.25 3C7.25 3.53043 7.46071 4.03914 7.83579 4.41421C8.21086 4.78929 8.71957 5 9.25 5C9.78043 5 10.2891 4.78929 10.6642 4.41421C11.0393 4.03914 11.25 3.53043 11.25 3C11.25 2.46957 11.0393 1.96086 10.6642 1.58579C10.2891 1.21071 9.78043 1 9.25 1C8.71957 1 8.21086 1.21071 7.83579 1.58579Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6V7.3125H12.3031C12.3031 7.3125 11.7952 10.0654 11.8959 11.8727C12.0042 13.8169 12.9562 16.7525 12.9562 16.7525L11.6827 17.0701L10.3418 11.6919C10.0502 10.6396 8.3567 10.6707 8.11726 11.705L6.77017 17.1079L5.49666 16.7904C5.49666 16.7904 6.45781 13.8473 6.57872 11.905C6.69203 10.0848 6.19688 7.3125 6.19688 7.3125H2V6H16Z"
      fill="currentColor"
    />
  </svg>,
  "PoseIcon",
);
