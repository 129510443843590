import { createSvgIcon } from "@mui/material";

export const Icon3D = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1953 3.3916C12.0391 3.31348 11.9219 3.31348 11.7656 3.3916L3.71875 6.32129L12 9.56348L20.2422 6.32129L12.1953 3.3916ZM3.25 17.0635C3.25 17.3369 3.40625 17.5713 3.64062 17.6494L11.375 20.501V10.6572L3.25 7.49316V17.0635ZM12.625 20.501L20.3203 17.6494C20.5547 17.5713 20.75 17.3369 20.75 17.0635V7.49316L12.625 10.6572V20.501ZM11.3359 2.18066C11.7656 2.06348 12.1953 2.06348 12.625 2.18066L20.75 5.18848C21.4922 5.46191 22 6.16504 22 6.94629V17.0635C22 17.8447 21.4922 18.5478 20.75 18.8213L12.625 21.79C12.1953 21.9463 11.7656 21.9463 11.3359 21.79L3.21094 18.8213C2.46875 18.5478 2 17.8447 2 17.0635V6.94629C2 6.16504 2.46875 5.46191 3.21094 5.18848L11.3359 2.18066Z"
      fill="currentColor"
    />
  </svg>,
  "Icon3D",
);
