import { createSvgIcon } from "@mui/material";

export const FlowIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1343 6.62109C20.1343 8.302 18.7895 9.60937 17.146 9.60937C16.2869 9.60937 15.5024 9.27319 14.9421 8.67554L9.30176 11.5144C9.33911 11.6638 9.37646 11.8506 9.37646 12C9.37646 12.1868 9.33911 12.3735 9.30176 12.5229L14.9421 15.3245C15.5024 14.7642 16.2869 14.3906 17.146 14.3906C18.7895 14.3906 20.1343 15.7354 20.1343 17.3789C20.1343 19.0598 18.7895 20.3672 17.146 20.3672C15.4651 20.3672 14.1577 19.0598 14.1577 17.3789C14.1577 17.0427 14.1951 16.6692 14.3071 16.3704L8.89087 13.6436C8.33057 14.4653 7.43408 14.9883 6.38818 14.9883C4.70728 14.9883 3.3999 13.6809 3.3999 12C3.3999 10.3564 4.70728 9.01172 6.38818 9.01172C7.43408 9.01172 8.33057 9.57202 8.89087 10.3938L14.3071 7.66699C14.1951 7.33081 14.1577 6.99463 14.1577 6.62109C14.1577 4.97754 15.4651 3.63281 17.146 3.63281C18.7895 3.63281 20.1343 4.97754 20.1343 6.62109ZM6.38818 13.793C7.02319 13.793 7.5835 13.4568 7.91968 12.8965C8.25586 12.3735 8.25586 11.6638 7.91968 11.1035C7.5835 10.5806 7.02319 10.207 6.38818 10.207C5.71582 10.207 5.15552 10.5806 4.81934 11.1035C4.48315 11.6638 4.48315 12.3735 4.81934 12.8965C5.15552 13.4568 5.71582 13.793 6.38818 13.793ZM18.939 6.62109C18.939 5.98608 18.5654 5.42578 18.0425 5.0896C17.4822 4.75342 16.7725 4.75342 16.2495 5.0896C15.6892 5.42578 15.353 5.98608 15.353 6.62109C15.353 7.29346 15.6892 7.85376 16.2495 8.18994C16.7725 8.52612 17.4822 8.52612 18.0425 8.18994C18.5654 7.85376 18.939 7.29346 18.939 6.62109ZM17.146 19.1719C17.781 19.1719 18.3413 18.8357 18.6775 18.2754C19.0137 17.7524 19.0137 17.0427 18.6775 16.4824C18.3413 15.9595 17.781 15.5859 17.146 15.5859C16.4736 15.5859 15.9133 15.9595 15.5771 16.4824C15.241 17.0427 15.241 17.7524 15.5771 18.2754C15.9133 18.8357 16.4736 19.1719 17.146 19.1719Z"
      fill="currentColor"
    />
  </svg>,
  "FlowIcon",
);
