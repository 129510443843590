import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useReducerWithURLParams<
  S extends object,
  IS extends object,
>(
  reducer: (payload: Partial<S & IS>) => Partial<S & IS>,
  searchParamMappers: Partial<Record<keyof S, (s: string) => unknown>>,
  initialState: S,
  initialInternalState?: IS,
): [S & IS, (newState: Partial<S & IS>) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const stateSearchParams = [];
  const initialStateWithParams = Object.fromEntries(
    Object.entries(initialState).map(([key, defaultValue]) => {
      const mapper = searchParamMappers[key];
      if (searchParams.has(key)) {
        stateSearchParams.push(key);
        if (mapper) {
          return [key, mapper(searchParams.get(key))];
        }
        return [key, searchParams.get(key)];
      }
      return [key, defaultValue];
    }),
  ) as S;
  const [state, setState] = useState({
    ...initialStateWithParams,
    ...initialInternalState,
    stateUpdatedAt: Date.now(),
  });

  useEffect(() => {
    if (stateSearchParams.length > 0) {
      setSearchParams(
        (oldSearchParams) => {
          const newSearchParams = new URLSearchParams(oldSearchParams);
          for (const key of stateSearchParams) {
            newSearchParams.delete(key);
          }
          return newSearchParams;
        },
        { replace: true },
      );
    }
  }, [stateSearchParams.length]);

  // handle case where search params are updated externally, without updating internal state
  useEffect(() => {
    const newState: Partial<S> = {};
    for (const [key, value] of searchParams.entries()) {
      const mapper = searchParamMappers[key];
      if (key in initialState) {
        newState[key] = mapper ? mapper(value) : value;
      }
    }
    if (Object.keys(newState).length > 0) {
      setState((oldState) => {
        return {
          ...oldState,
          ...initialState,
          ...newState,
          stateUpdatedAt: Date.now(),
        };
      });
    }
  }, [searchParams]);

  // build updateState function that updates state with reducer
  const updateState = useCallback(
    (payload: Partial<S & IS>) => {
      setState((oldState) => {
        return { ...oldState, ...reducer(payload), stateUpdatedAt: Date.now() };
      });
    },
    [setState],
  );

  return [state, updateState];
}
