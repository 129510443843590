import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { useIsOnline } from "../../../../../../../../hooks/useIsOnline";
import { Icon, IconButton } from "../../../../../../../ui-v2";
import { useNavigationStore } from "../../../../../../state";
import { ConnectionIndicator } from "./components/ConnectionIndicator/ConnectionIndicator";
import { WorkspaceSelectionButton } from "./components/WorkspaceSelectionButton/WorkspaceSelectionButton";

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const PrimaryNavigationHeader = () => {
  const isOnline = useIsOnline();
  const { expanded, setExpanded } = useNavigationStore();

  const isCloseButtonVisible = isOnline && expanded;
  const isOfflineIndicatorVisible = !isCloseButtonVisible && !isOnline;

  return (
    <Container>
      <WorkspaceSelectionButton />
      {isCloseButtonVisible && (
        <IconButton
          size="large"
          variant="quaternary"
          aria-label="Close Navigation"
          onClick={() => setExpanded(false)}
        >
          <Icon icon={regular("arrow-left-from-line")} />
        </IconButton>
      )}
      {isOfflineIndicatorVisible && <ConnectionIndicator />}
    </Container>
  );
};
