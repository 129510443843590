import { Drawer } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";

import { PRIMARY_NAVIGATION_WIDTH } from "../../constants";
import { useNavigationStore } from "../../state";
import { PrimaryNavigationContent } from "./components/PrimaryNavigationContent/PrimaryNavigationContent";

const PrimaryNavigationDrawer = styled(Drawer)({
  width: PRIMARY_NAVIGATION_WIDTH,

  "& .MuiDrawer-paper": {
    border: "none",
    width: PRIMARY_NAVIGATION_WIDTH,
  },
});

export const PrimaryNavigation = () => {
  const { pathname } = useLocation();
  const { expanded, setExpanded } = useNavigationStore();
  const isSessionContext = useMatch("/:workspace/session/:sessionId");

  const handlePrimaryNavigationClose = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (expanded) {
      handlePrimaryNavigationClose();
    }
  }, [pathname]);

  if (isSessionContext) {
    return (
      <>
        <PrimaryNavigationDrawer
          variant="temporary"
          open={expanded}
          onClose={handlePrimaryNavigationClose}
        >
          <PrimaryNavigationContent />
        </PrimaryNavigationDrawer>
      </>
    );
  }

  return (
    <PrimaryNavigationDrawer variant="permanent">
      <PrimaryNavigationContent />
    </PrimaryNavigationDrawer>
  );
};
