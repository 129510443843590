import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { icon as faIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Box,
  CircularProgress,
  ListItemIconProps,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  Stack,
  styled,
  Theme,
} from "@mui/material";
import { forwardRef } from "react";

import { Icon, ListItemIcon, ListItemText } from "../..";

declare module "@mui/material/MenuItem" {
  interface MenuItemOwnProps {
    variant?: "primary" | "secondary" | "tertiary";
    icon?: React.ReactElement;
    showSelectedIcon?: boolean;
    endLabel?: string;
    endIcon?: React.ReactElement;
    hasSubMenu?: boolean;
    ListItemIconProps?: ListItemIconProps;
    loading?: boolean;
  }
}

const StyledSelectedIcon = styled(Icon)(
  ({ theme }: { theme: Theme; icon: IconDefinition }) => ({
    color: theme.palette.secondary[50],
    fontSize: theme.typography.small.fontSize,
  }),
);

const Loader = styled(CircularProgress)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "0 auto",
});

const MenuItemNoForward = <C extends React.ElementType>(
  props: MenuItemProps<C, { component?: C }>,
  ref: React.Ref<HTMLLIElement>,
) => {
  const {
    variant = "secondary",
    icon,
    endLabel,
    endIcon,
    disabled = false,
    loading = false,
    selected = false,
    showSelectedIcon = false,
    hasSubMenu = false,
    ListItemIconProps = {},
    children,
    ...rest
  } = props;

  return (
    <MuiMenuItem
      variant={variant}
      selected={selected}
      disabled={disabled || loading}
      {...rest}
      ref={ref}
    >
      {loading && <Loader size={20} />}
      {icon && <ListItemIcon {...ListItemIconProps}>{icon}</ListItemIcon>}
      <Stack spacing={6} direction="row" alignItems="center" flex={1}>
        <ListItemText primary={children} />
        <Stack spacing={2} direction="row" alignItems="center">
          <ListItemText secondary={endLabel} />
          {endIcon}
          {hasSubMenu && (
            <Icon
              size="small"
              icon={faIcon({
                name: "caret-right",
                family: "sharp",
                style: "solid",
              })}
            />
          )}
          {showSelectedIcon && (
            <Box sx={{ opacity: selected ? 1 : 0 }}>
              <StyledSelectedIcon
                icon={faIcon({
                  name: "check",
                  family: "sharp",
                  style: "solid",
                })}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </MuiMenuItem>
  );
};

export const MenuItem = forwardRef(MenuItemNoForward);
