import * as yup from "yup";

import { roundTo } from "../../../../utils/numbers";
import {
  MAX_DENOISING_STEPS,
  MAX_PROMPT_STRENGTH,
  MAX_SEED,
  MAX_UPSCALE_CREATIVITY,
  MAX_UPSCALE_DIMENSION,
  MAX_UPSCALE_FACTOR,
  MAX_UPSCALE_RESEMBLANCE,
  MIN_DENOISING_STEPS,
  MIN_PROMPT_STRENGTH,
  MIN_SEED,
  MIN_UPSCALE_CREATIVITY,
  MIN_UPSCALE_FACTOR,
  MIN_UPSCALE_RESEMBLANCE,
  ReferenceImageType,
} from "../../constants";
import { FORM_FIELDS_UPSCALE, FormTypeUpscale } from "./constants";

export const getIsUpscaleTargetFileValid = (upscaleTargetFile) =>
  upscaleTargetFile.width * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION &&
  upscaleTargetFile.height * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION;

export const validationSchemaUpscale = yup.object().shape({
  [FORM_FIELDS_UPSCALE.TARGET_FILE]: yup
    .object()
    .required("Upscale reference file is required.")
    .test(
      "upscale-target-file-dimensions",
      `The image dimensions have to be at most ${MAX_UPSCALE_DIMENSION / 2}px × ${MAX_UPSCALE_DIMENSION / 2}px.`,
      (value: ReferenceImageType) => {
        if (value?.width && value?.height) {
          return getIsUpscaleTargetFileValid(value);
        }

        return true;
      },
    ),
  [FORM_FIELDS_UPSCALE.PROMPT_TEXT]: yup.string(),
  [FORM_FIELDS_UPSCALE.NEGATIVE_PROMPT_TEXT]: yup.string(),
  [FORM_FIELDS_UPSCALE.SEED]: yup
    .number()
    .nullable()
    .test(
      "is-valid-seed",
      `Seed must be empty or an integer between ${MIN_SEED} and ${MAX_SEED}.`,
      (value) => value === null || (value >= MIN_SEED && value <= MAX_SEED),
    ),
  [FORM_FIELDS_UPSCALE.PROMPT_STRENGTH]: yup
    .number()
    .nullable()
    .min(
      MIN_PROMPT_STRENGTH,
      `Prompt strength must be at least ${MIN_PROMPT_STRENGTH}.`,
    )
    .max(
      MAX_PROMPT_STRENGTH,
      `Prompt strength must be at most ${MAX_PROMPT_STRENGTH}.`,
    ),
  [FORM_FIELDS_UPSCALE.DENOISING_STEPS]: yup
    .number()
    .nullable()
    .min(
      MIN_DENOISING_STEPS,
      `Denoising steps must be at least ${MIN_DENOISING_STEPS}.`,
    )
    .max(
      MAX_DENOISING_STEPS,
      `Denoising steps must be at most ${MAX_DENOISING_STEPS}.`,
    ),
  [FORM_FIELDS_UPSCALE.UPSCALE_FACTOR]: yup
    .number()
    .typeError("Invalid value.")
    .required("Upscale factor is required")
    .min(MIN_UPSCALE_FACTOR, `Upscale factor at least ${MIN_UPSCALE_FACTOR}.`)
    .max(
      MAX_UPSCALE_FACTOR,
      `Scale factor must be at most ${MAX_UPSCALE_FACTOR}.`,
    )
    .when(
      [FORM_FIELDS_UPSCALE.TARGET_FILE],
      ([targetFile]: [FormTypeUpscale["targetFile"]], schema) => {
        let maxWidthScale = MAX_UPSCALE_FACTOR;
        let maxHeightScale = MAX_UPSCALE_FACTOR;

        if (targetFile && targetFile.width && targetFile.height) {
          maxWidthScale = MAX_UPSCALE_DIMENSION / targetFile.width;
          maxHeightScale = MAX_UPSCALE_DIMENSION / targetFile.height;

          const maxScaleFactor = roundTo(
            Math.min(maxWidthScale, maxHeightScale, MAX_UPSCALE_FACTOR),
            1,
            "floor",
          );
          const isUpscaleTargetFileValid =
            getIsUpscaleTargetFileValid(targetFile);

          if (maxScaleFactor < MAX_UPSCALE_FACTOR && isUpscaleTargetFileValid) {
            return schema.max(
              maxScaleFactor,
              `Scale factor must be at most ${maxScaleFactor} to stay within ${MAX_UPSCALE_DIMENSION} pixels.`,
            );
          }
        }
      },
    ),
  [FORM_FIELDS_UPSCALE.CREATIVITY]: yup
    .number()
    .typeError("Invalid value.")
    .nullable()
    .min(
      MIN_UPSCALE_CREATIVITY,
      `Upscale creativity must be at least ${MIN_UPSCALE_CREATIVITY}.`,
    )
    .max(
      MAX_UPSCALE_CREATIVITY,
      `Upscale creativity must be at most ${MAX_UPSCALE_CREATIVITY}.`,
    ),
  [FORM_FIELDS_UPSCALE.RESEMBLANCE]: yup
    .number()
    .typeError("Invalid value.")
    .nullable()
    .min(
      MIN_UPSCALE_RESEMBLANCE,
      `Upscale resemblance must be at least ${MIN_UPSCALE_RESEMBLANCE}.`,
    )
    .max(
      MAX_UPSCALE_RESEMBLANCE,
      `Upscale resemblance must be at most ${MAX_UPSCALE_RESEMBLANCE}.`,
    ),
}) as yup.ObjectSchema<FormTypeUpscale>;
