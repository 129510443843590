import { Theme } from "@mui/material";
import { ButtonBase } from "@mui/material";
import { styled } from "@mui/system";
import classNames from "classnames";
import React from "react";

import { Tooltip } from "../../..";
import { ForgeIcon } from "../../../icons/ForgeIcon";

interface ForgeButtonProps {
  styleName: string;
  open: boolean;
  onClick: (event: React.MouseEvent) => void;
  className?: string;
}

const ButtonContainer = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ theme?: Theme }>(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary["200"],
  backgroundColor: theme.palette.secondary["500"],
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  width: 40,
  height: 40,
  padding: theme.spacing(2.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  transition: theme.transitions.create(["border", "transform", "opacity"]),
  transform: "scale(1)",
  border: `1px solid transparent`,
  opacity: 0,

  "&:hover": {
    transform: "scale(1.1)",
    border: `1px solid ${theme.palette.secondary["200"]}`,
  },
}));

export const ForgeButton = (props: ForgeButtonProps) => {
  const { styleName, className, ...rest } = props;
  const label = `Forge with ${styleName}`;
  return (
    <Tooltip title={label}>
      <ButtonContainer
        className={classNames(className, "Layer-ForgeButton")}
        {...rest}
        aria-label={label}
      >
        <ForgeIcon weight="regular" />
      </ButtonContainer>
    </Tooltip>
  );
};
