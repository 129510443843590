import { UpdatesConfig } from "@urql/exchange-graphcache";
import { gql } from "urql";

import {
  FileStatus,
  MutationDeleteDriveItemsArgs,
  MutationDeleteStyleArgs,
  MutationUpdateStyleArgs,
} from "./schema.graphql";

const updatesConfig: Partial<UpdatesConfig> = {
  Mutation: {
    deleteStyle: (result, { input }: MutationDeleteStyleArgs, cache) => {
      if (input.styleId) {
        cache.invalidate({ __typename: "Style", id: input.styleId });
      }
    },
    updateStyle: (result, { input }: MutationUpdateStyleArgs, cache) => {
      const isStatusUpdate = input.styleId && input.status;

      if (isStatusUpdate) {
        cache.invalidate({ __typename: "Style", id: input.styleId });
      }
    },
    deleteDriveItems: (
      result,
      { input }: MutationDeleteDriveItemsArgs,
      cache,
    ) => {
      if (input.fileIds) {
        const FileStatusFragment = gql`
          fragment FileStatusFragment on File {
            id
            status
          }
        `;
        input.fileIds.forEach((fileId) => {
          cache.writeFragment(FileStatusFragment, {
            __typename: "File",
            id: fileId,
            status: "DELETED" as FileStatus,
          });
        });
      }
    },
  },
  Query: {},
};

export default updatesConfig;
