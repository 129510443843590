// Provider wrapper
import { yupResolver } from "@hookform/resolvers/yup";
import { createContext, ReactNode } from "react";
import { useRef } from "react";
import { Resolver, useForm } from "react-hook-form";

import { DEFAULT_2D_FORM_VALUES, FormType2D } from "../constants";
import { validationSchema2D } from "../forms/2D/validationSchema2D";
import { DEFAULT_3D_FORM_VALUES, FormType3D } from "../forms/3D/constants";
import { meshValidationSchema } from "../forms/3D/validationSchema3D";
import {
  DEFAULT_REALTIME_FORM_VALUES,
  FormTypeRealtime,
} from "../forms/Realtime/constants";
import { realtimeValidationSchema } from "../forms/Realtime/validationSchemaRealtime";
import {
  DEFAULT_UPSCALE_FORM_VALUES,
  FormTypeUpscale,
} from "../forms/Upscale/constants";
import { validationSchemaUpscale } from "../forms/Upscale/validationSchemaUpscale";
import {
  inferenceForm2DStore,
  InferenceFormProps,
  InferenceFormStore,
} from "./store";

export const InferenceFormContext = createContext<InferenceFormStore | null>(
  null,
);

interface InferenceFormProviderProps extends InferenceFormProps {
  children?: ReactNode;
}

export function InferenceFormProvider({
  children,
  ...props
}: InferenceFormProviderProps) {
  // initialize forms with default values - these forms do not change once initialized
  const inferenceForm2D = useForm<FormType2D>({
    resolver: yupResolver(validationSchema2D) as Resolver<FormType2D>,
    mode: "onBlur",
    defaultValues: DEFAULT_2D_FORM_VALUES,
  });
  const inferenceFormUpscale = useForm<FormTypeUpscale>({
    resolver: yupResolver(validationSchemaUpscale) as Resolver<FormTypeUpscale>,
    mode: "onBlur",
    defaultValues: DEFAULT_UPSCALE_FORM_VALUES,
  });
  const inferenceForm3D = useForm<FormType3D>({
    resolver: yupResolver(meshValidationSchema) as Resolver<FormType3D>,
    mode: "onBlur",
    defaultValues: DEFAULT_3D_FORM_VALUES,
  });
  const inferenceFormRealtime = useForm<FormTypeRealtime>({
    resolver: yupResolver(
      realtimeValidationSchema,
    ) as Resolver<FormTypeRealtime>,
    mode: "onBlur",
    defaultValues: DEFAULT_REALTIME_FORM_VALUES,
  });

  const storeRef = useRef<InferenceFormStore>();
  if (!storeRef.current) {
    // create the store only once
    storeRef.current = inferenceForm2DStore({
      ...props,
      activeMode: "CREATE",
      inferenceForm2D,
      inferenceFormUpscale,
      inferenceForm3D,
      inferenceFormRealtime,
    });
  }
  return (
    <InferenceFormContext.Provider value={storeRef.current}>
      {children}
    </InferenceFormContext.Provider>
  );
}
