import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, styled } from "@mui/system";
import { useIntercom } from "react-use-intercom";

import { Button, Icon, Typography } from "../../../../../../../../../ui-v2";

const StyledButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  border: "none",
  borderRadius: "4px",
  fontWeight: "400",

  "&:hover": {
    border: "none",
  },

  "& .MuiButton-startIcon": {
    transition: "margin 0.3s ease-out",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(1),
    fontSize: "16px",
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },
}));

export const IntercomButton = (props) => {
  const { show, hide, isOpen } = useIntercom();

  const handleIntercomToggle = (event) => {
    event.preventDefault();

    if (isOpen) {
      hide();
    } else {
      show();
    }
  };

  const getLabel = () => {
    return isOpen ? "Close Chat" : "Talk To Us";
  };

  return (
    <StyledButton
      {...props}
      onClick={handleIntercomToggle}
      variant={isOpen ? "contained" : "text"}
      color="secondary"
      fullWidth
      startIcon={
        <Box
          sx={{
            height: 28,
            width: 28,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!isOpen && (
            <Icon
              icon={icon({
                name: "message-dots",
                family: "sharp",
                style: "regular",
              })}
            />
          )}
          {isOpen && (
            <Icon
              icon={icon({
                name: "message-dots",
                family: "sharp",
                style: "solid",
              })}
            />
          )}
        </Box>
      }
    >
      <Typography variant="small" fontWeight={500} color="secondary.100">
        {getLabel()}
      </Typography>
    </StyledButton>
  );
};
