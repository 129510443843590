import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface LinkProps extends MuiLinkProps {}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href = "", ...rest }: LinkProps, ref) => {
    const isExternalLink = href.startsWith("http");
    if (isExternalLink) {
      return (
        <MuiLink
          target="_blank"
          rel="noopener noreferrer"
          {...rest}
          href={href}
          ref={ref}
        />
      );
    } else {
      return <MuiLink component={RouterLink} to={href} {...rest} ref={ref} />;
    }
  },
);
