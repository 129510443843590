import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";

interface NavigationState {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  activeWorkspaceName: string | null;
  setActiveWorkspaceName: (workspaceId: string) => void;
}

export const useNavigationStore = create<NavigationState>()(
  persist(
    subscribeWithSelector((set) => ({
      expanded: false,
      activeWorkspaceName: null,

      toggleExpanded: () => {
        set((state) => {
          const newExpanded = !state.expanded;
          return { expanded: newExpanded };
        });
      },

      setExpanded: (expanded: boolean) => {
        set({ expanded });
      },

      setActiveWorkspaceName: (activeWorkspaceName: string) => {
        set({ activeWorkspaceName });
      },
    })),
    {
      version: 1,
      name: "nav",
      getStorage: () => localStorage,
    },
  ),
);
