import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { CircularProgress, Icon } from "../../..";
import { StyleStatus } from "../../types";

interface StatusIndicatorProps {
  styleStatus: StyleStatus;
}

const Container = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary["200"],
  backgroundColor: theme.palette.secondary["950"],
  borderBottomRightRadius: 6,
  padding: theme.spacing(1.5),
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1, 1.5),
  lineHeight: 1,

  "& > svg": {
    color: theme.palette.secondary["300"],
  },
}));

export const StatusIndicator = (props: StatusIndicatorProps) => {
  const { styleStatus } = props;

  if (styleStatus === "DRAFT") {
    return (
      <Container>
        <Icon size="medium" icon={regular("pen-circle")} />
        <span>Draft</span>
      </Container>
    );
  }

  if (styleStatus === "INITIALIZING" || styleStatus === "BUILDING") {
    return (
      <Container>
        <CircularProgress size={14} />
        <span>Training</span>
      </Container>
    );
  }

  if (styleStatus === "CANCELLED") {
    return (
      <Container>
        <Icon size="medium" icon={regular("stop-circle")} />
        <span>Cancelled</span>
      </Container>
    );
  }

  if (styleStatus === "FAILED") {
    return (
      <Container>
        <Icon size="medium" icon={regular("bug")} />
        <span>Failed</span>
      </Container>
    );
  }

  if (styleStatus === "DELETED") {
    return (
      <Container>
        <Icon size="medium" icon={regular("trash")} />
        <span>Deleted</span>
      </Container>
    );
  }

  return null;
};
